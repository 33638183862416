<template>
    <div v-if="showNuevaTransferencia">
        <div class="modal" tabindex="-1">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <form v-on:submit.prevent="storeTransferencia()">
                        <div class="modal-body" style="color:black;">
                            <center>
                                <p style="text-transform: uppercase; font-size:15px">
                                    <span v-if="is_ingreso == 'INGRESO'">ASIGNAR PRESUPUESTO</span>
                                    <span v-if="is_ingreso == 'EGRESO'">CONCLUIR PRESUPUESTO</span>
                                </p>
                            </center>

                            <div class="row my-2">

                                <div class="mb-1 col-12">

                                    <label for="grado_confianza" v-if="is_ingreso == 'INGRESO'">Sacar de la caja </label>
                                    <label for="grado_confianza" v-if="is_ingreso == 'EGRESO'">Devolver a la caja </label>

                                    <select class="form-select" v-model="id_caja_auxiliar"
                                        :style="[id_caja_auxiliar == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                        required="required">
                                        <option value="">Seleccione una opción</option>
                                        <option v-for="caja in cajas" :value="caja.id" :key="caja">{{ caja.nombre }}
                                        </option>
                                    </select>
                                </div>

                                <div class="mb-1 col-12">
                                    <label for="id_caja">Fecha</label>
                                    <input class="form-control Float"
                                        :style="[new_movimiento.fecha == '' ? { 'background-color': 'white' } : { 'background-color': '#ebfbe9' }]"
                                        type="date" v-model="new_movimiento.fecha" required="required">
                                </div>


                                <div class="mb-1 col-12">
                                    <label for="id_caja">Descripción</label>
                                    <textarea class="form-control Float"
                                        :style="[new_movimiento.descripcion == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                        type="text" placeholder="Descripción" v-model="new_movimiento.descripcion" rows="2"
                                        cols="2" required="required"></textarea>
                                </div>

                                <div class="mb-1 col-12">
                                    <label for="id_caja">Monto (Bs.)</label>
                                    <input class="form-control Float" type="number"
                                        :style="[new_movimiento.monto == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                        placeholder="Monto" step="any" v-model="new_movimiento.monto" required="required">

                                </div>


                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary"
                                v-on:click="showNuevaTransferencia = false">Cerrar</button>
                            <button type="submit" class="btn btn-primary">Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
axios.defaults.baseURL = process.env.API_URL;

export default {
    name: "nuevoMovimiento",
    data: function () {
        return {
            showNuevaTransferencia: false,
            new_movimiento: {},
            id_caja_auxiliar: '',
            cajas: [],
            is_ingreso: '',
            dats_administracion: []
        };
    },
    components: {

    },
    created() {
    },
    methods: {

        iniciarFormulario: function () {
            this.new_movimiento = {
                "id_caja_origen": "",
                "id_caja_destino": "",
                "fecha": this.fechaActualYMD(),
                "descripcion": "",
                "monto": "",
            }
        },

        iniciarNuevaTransferencia: async function (dats_administracion, is_ingreso) {
            this.is_ingreso = is_ingreso;
            this.dats_administracion = dats_administracion;
            this.getAdministracions();
            this.iniciarFormulario();
            this.showNuevaTransferencia = true;
            if (this.is_ingreso == 'INGRESO') {
                this.new_movimiento.descripcion = 'Asignación por ' + dats_administracion.nombre;
            }
            if (this.is_ingreso == 'EGRESO') {
                this.new_movimiento.descripcion = 'Conclusión por ' + dats_administracion.nombre;
            }
        },

        getAdministracions: function () {


            var dats_usuario = JSON.parse(localStorage.getItem("usuarioAuth"));
            if (dats_usuario) {
                axios.get('/api/getAdministracion/'+dats_usuario.categoria_tipos[0].id+'/ACTIVO').then(response => {
                    this.cajas = response.data;
                });
            }
            else {
                return this.$router.replace("/login");
            }


        },


        storeTransferencia: function () {

            if (this.is_ingreso == 'INGRESO') {
                //CAJA ORIGEN  
                this.new_movimiento.id_caja_origen = this.id_caja_auxiliar;
                this.new_movimiento.id_caja_destino = this.dats_administracion.id;
            }
            if (this.is_ingreso == 'EGRESO') {
                //CAJA DESTINO
                this.new_movimiento.id_caja_origen = this.dats_administracion.id;
                this.new_movimiento.id_caja_destino = this.id_caja_auxiliar;
            }

            axios.post('/api/storeTransferencia', this.new_movimiento).then(() => {
                this.showNuevaTransferencia = false;
                this.$emit('reloadMovimientos');
            });
        },


        fechaActualYMD() {
            let fechaHora = new Date();
            let anio = fechaHora.getFullYear();
            let mes = (fechaHora.getMonth() + 1).toString().padStart(2, '0');
            let dia = fechaHora.getDate().toString().padStart(2, '0');
            let fechaHoraFormateada = anio + '-' + mes + '-' + dia;
            return fechaHoraFormateada;
        },

    },
    mounted() {


    },
};
</script>

<style>
.table_padd th {
    padding: 0.2rem;
}

.table_padd td {
    padding: 0.2rem;
}
</style>