import { createWebHistory, createRouter } from 'vue-router'
import LoginAuth from '../views/M_Login/LoginAuth.vue';
import Personas from '../views/M_Persona/Personas.vue';
import Categoria from '../views/M_Categorias/Categorias/Categoria.vue';
import Transferencia from '../views/M_Transferencia/Tranferir.vue';
import ResumenSubCategoria from '../views/M_Reportes/Resumenes/ResumenSubCategoria.vue';
import Administracion from '../views/Administracion/Administracion.vue';
import Movimiento from '../views/M_Movimiento/Movimientos.vue';
import Tarea from '../views/M_Tarea/Tareas.vue';
import RegistroUsuario from '../views/M_Login/RegistroUsuario.vue';

const routes = [

    {
        name: 'administracion',
        path: '/administracion/:dats_categoria',
        component: Administracion,
        props: true
    },

    {
        name: 'movimientos',
        path: '/movimientos',
        component: Movimiento
    },

    {
        path: '/transferencia',
        name: 'Transferencia',
        component: Transferencia,
    },

    {
        name: 'personas',
        path: '/personas',
        component: Personas
    },

    {
        name: 'categoria',
        path: '/categorias',
        component: Categoria
    },

    {
        path: '/resumenSubCategorias',
        name: 'ResumenSubCategorias',
        component: ResumenSubCategoria
    },

    {
        name: 'login',
        path: '/login',
        component: LoginAuth
    },


    {
        // name: 'login',
        path: '/',
        component: LoginAuth
    },

    {
        name: 'tareas',
        path: '/tareas',
        component: Tarea
    },

    {
        name: 'registrate',
        path: '/registrate',
        component: RegistroUsuario
    },


];




const router = createRouter({
    history: createWebHistory(),
    routes
});


export default router;