<template>
    <NavbarApp>
        <div class="row mt-2">
            <div class="col-xl-12">

                <div class="card">
                    <div class="card-header">
                        <div class="card-actions float-end">
                            Nuevo
                            <a href="#" data-bs-toggle="dropdown" data-bs-display="static"
                                v-on:click="createCategoriaTipo()">
                                <i class="align-middle fa fa-plus"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-end">
                                <a class="dropdown-item" href="#" v-on:click="createCategoriaTipo()">Registrar nuevo</a>
                            </div>
                        </div>
                        <h5 class="card-title mb-0">CATEGORIAS</h5>
                    </div>
                    <div class="card-body">

                        <div class="row">
                            <table class="table table-striped table_padd" id="tabla_a_buscar">

                                <thead>
                                    <tr>
                                        <th>Nº</th>
                                        <th>Nombre</th>
                                        <!-- <th>Descripcion</th> -->
                                        <th class="text-end">Opciones</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="(dato, num) in categoria_tipos" :key="dato">
                                        <td>{{ num + 1 }}</td>
                                        <td>{{ dato.nombre }}</td>
                                        <!-- <td>{{ dato.descripcion }}</td> -->
                                        <td class="text-end">
                                            <button class="btn btn-sm btn-primary" v-on:click="editarCategoriaTipo(dato)"><i
                                                    class="fa fa-pencil-alt"></i></button>
                                            <!-- <button class="btn btn-sm btn-danger" v-on:click="eliminarCategoriaTipo(dato)"><i
                    class="fa fa-trash"></i></button> -->
                                            <button class="btn btn-sm btn-success" v-on:click="tablaTipoCategoria(dato)"><i
                                                    class="fa fa-eye"></i> </button>



                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

            </div>
        </div>



        <div v-if="showNuevaCategoria">
            <div class="modal" tabindex="-1">
                <div class="modal-dialog modal-md">
                    <div class="modal-content">
                        <div class="modal-body" style="color:black;">

                            <p style="text-transform:uppercase;" class="align-self-center text-center">
                                <b>NUEVA CATEGORIA</b>
                            </p>



                            <form @submit.prevent="storeCategoriaTipo()">

                                <div class="row">

                                    <div class="mb-3 col-md-12">
                                        <label for="nombre">Nombre</label>
                                        <input class="form-control String" type="text" v-model="new_categoria_tipo.nombre"
                                            placeholder="Nombre" required="required">

                                    </div>
                                    <div class="mb-3 col-md-12">
                                        <label for="descripcion">Descripcion</label>
                                        <input class="form-control String" type="text"
                                            v-model="new_categoria_tipo.descripcion" placeholder="Descripcion"
                                            required="required">

                                    </div>
                                </div>

                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary"
                                        v-on:click="showNuevaCategoria = false">Cerrar</button>
                                    <button type="submit" class="btn btn-primary">Guardar</button>
                                </div>

                            </form>


                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div v-if="showEditarCategoria">
            <div class="modal" tabindex="-1">
                <div class="modal-dialog modal-md">
                    <div class="modal-content">
                        <div class="modal-body" style="color:black;">

                            <p style="text-transform:uppercase;" class="align-self-center text-center">
                                <b>EDITAR CATEGORIA</b>
                            </p>

                            <form @submit.prevent="updateCategoriaTipo()">

                                <div class="row">
                                    <div class="mb-3 col-sm-12">
                                        <label for="nombre">Nombre</label>
                                        <input class="form-control String" type="text" v-model="dats_categoria_tipo.nombre"
                                            maxlength="255" required="required">

                                    </div>
                                    <div class="mb-3 col-sm-12">
                                        <label for="descripcion">Descripcion</label>
                                        <input class="form-control String" type="text"
                                            v-model="dats_categoria_tipo.descripcion" maxlength="255" required="required">

                                    </div>

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary"
                                        v-on:click="showEditarCategoria = false">Cerrar</button>
                                    <button type="submit" class="btn btn-primary">Guardar</button>
                                </div>

                            </form>

                        </div>

                    </div>
                </div>
            </div>
        </div>


    </NavbarApp>
</template>

<script>

// import NavbarApp from "../adminstracion/navbar/NavbarApp";
import NavbarApp from "../../Layouts/carrito/NavbarCarrito.vue";

import axios from 'axios';
axios.defaults.baseURL = process.env.API_URL;
const $ = require('jquery');

export default {
    name: "categoriasUsuario",
    data: function () {
        return {
            showNuevaCategoria: false,
            showEditarCategoria: false,

            categoria_tipos: [],
            new_categoria_tipo: {},
            dats_categoria_tipo: [],
            id_usuario: '',
        };
    },
    components: {
        NavbarApp
    },
    created() {
        var dats_usuario    = JSON.parse(localStorage.getItem("usuarioAuth"));
        if(dats_usuario) {
            this.id_usuario = dats_usuario.id;
            this.getCategoriaTipo();
        }
        else {
            return this.$router.replace("/login");
        }

    },
    methods: {



        getCategoriaTipo: function () {


            axios.get('/api/getCategoriasUsuario/' + this.id_usuario).then(response => {
                this.categoria_tipos = response.data;

            }).catch(error => {

                if (error.response) {
                    if (error.response.statusText === 'Unauthorized') {
                        return this.$router.replace("/login");
                    }
                }
                else {
                    return this.$router.replace("/login");
                }
            });
        },
        createCategoriaTipo: function () {
            this.new_categoria_tipo = {
                'id_usuario': this.id_usuario,
                'nombre': '',
                'descripcion': '',
                'estado': 1
            }
            this.showNuevaCategoria = true;
        },
        storeCategoriaTipo: function () {
            axios.post('/api/categoria-tipos', this.new_categoria_tipo).then(response => {
                response
                this.getCategoriaTipo();
                this.showNuevaCategoria = false;
            });
        },
        editarCategoriaTipo: function (dato) {
            this.showEditarCategoria = true;
            this.dats_categoria_tipo = dato;
        },
        updateCategoriaTipo: function () {
            axios.put('/api/categoria-tipos/' + this.dats_categoria_tipo.id, this.dats_categoria_tipo).then(response => {
                response
                this.getCategoriaTipo();
                this.dats_categoria_tipo = [];
                this.showEditarCategoria = false;
            });
        },
        eliminarCategoriaTipo: function (dato) {
            $('.eliminar_categoria_tipo').modal('show');
            this.dats_categoria_tipo = dato;
        },

        deleteCategoriaTipo: function () {
            axios.delete('/api/categoria-tipos/' + this.dats_categoria_tipo.id).then(response => {
                response
                this.getCategoriaTipo();
                this.dats_categoria_tipo = [];
            });
        },

        tablaTipoCategoria: function (dato) {
            // this.$router.push({ path: "subcategorias", query: dato });
            dato = JSON.stringify(dato);
            this.$router.push({ name: 'administracion', params: { dats_categoria: dato } });

        }

    },
    mounted() {

    },


};
</script>

<style>
.table_padd th {
    padding: 0.2rem;
}

.table_padd td {
    padding: 0.2rem;
}
</style>