<template>
    <NavbarApp ref="cambiarOpcion">


        <div class="row">






            <!-- <div class="col-4">
                <div class="card">
                    <div class="card-body py-1" style="font-size: 14px;">
                        <strong>{{ parseFloat(ingresos).toFixed(2) }} </strong> <br>
                        <span>Ingresos</span> <small>Bs. </small>
                    </div>
                </div>
            </div>



            <div class="col-4">
                <div class="card">
                    <div class="card-body py-1" style="font-size: 14px;">
                        <strong>{{ parseFloat(egresos).toFixed(2) }} </strong> <br>
                        <span>Egresos</span> <small>Bs. </small>
                    </div>
                </div>
            </div> -->
            <div class="col-12">
                <div class="card">
                    <div class="card-body py-1" style="font-size: 15px;">
                        <small>Bs. </small> <strong>{{ parseFloat(saldo).toFixed(2) }} </strong> <br>
                        <span>Saldo</span>
                    </div>
                </div>
            </div>

            <div class="col-12">

                <div class="card">
                    <div class="card-header">
                        <div class="card-actions float-end">
                            <div class="dropdown position-relative">
                                Opciones
                                <a href="#" data-bs-toggle="dropdown" data-bs-display="static">
                                    <i class="align-middle fa fa-list"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-end">
                                    <a class="dropdown-item" href="#" v-on:click="nuevaAdministracion()">Registrar nuevo</a>
                                    <a class="dropdown-item" href="#" v-on:click="cambiarEstado('ACTIVO')">Activos</a>
                                    <a class="dropdown-item" href="#" v-on:click="cambiarEstado('CONCLUIDO')">Concluidos</a>
                                </div>
                            </div>
                        </div>

                        <h5 class="card-title mb-0" style="font-size: 13px; font-weight:600; text-transform: uppercase;">
                            {{ dats_categoria_tipo.nombre }}
                        </h5>

                    </div>
                    <div class="card-body">


                        <table class="table table-striped table_padd">
                            <thead>
                                <tr>
                                    <th>Nº</th>
                                    <th>NOMBRE</th>
                                    <th>SALDO</th>
                                    <th class="text-end"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(dato, num) in adminstracions" :key="dato">
                                    <td>{{ num + 1 }}</td>
                                    <td style="text-transform: capitalize;">

                                        <div v-if="dato.categoria.nombre == 'Prestamos'"
                                            v-on:click="detalleAdministracion(dato)">
                                            <p class="interlineado2">

                                                <span v-if="dato.is_afavor == 'SI'"
                                                    style="color:#2b4ed7; font-size:12px;">{{
                                                        dato.usuario_destino.name }}</span>
                                                <span v-if="dato.is_afavor == 'NO'"
                                                    style="color:#da570b; font-size:12px;">{{
                                                        dato.usuario_destino.name }}</span> <br>

                                                <small style="font-size:11px;">{{ dato.nombre.substring(0, 50) }}</small>
                                                <br>
                                                <small v-if="dato.is_afavor == 'SI'"><b>YO PRESTANDO</b></small>
                                                <small v-if="dato.is_afavor == 'NO'"><b>YO PRESTANDOME</b></small>
                                            </p>
                                        </div>
                                        <div v-else>

                                            <span v-on:click="detalleAdministracion(dato)">{{ dato.nombre.substring(0,
                                                50) }}</span>
                                            <span v-if="dato.categoria.nombre == 'Prestamos'">
                                                <br> A: {{ dato.usuario_destino.name }}
                                            </span>
                                        </div>


                                    </td>
                                    <td>

                                        <span v-if="dato.ingresos - dato.egresos <= 0" style="color:red;">
                                            {{ parseFloat(dato.ingresos -
                                                dato.egresos).toFixed(2) }}
                                        </span>
                                        <span v-if="dato.ingresos - dato.egresos > 0" style="color:green;">
                                            {{ parseFloat(dato.ingresos -
                                                dato.egresos).toFixed(2) }}
                                        </span>
                                    </td>
                                    <td class="text-end">

                                        <!-- <button class="btn btn-sm btn-success" v-on:click="detalleAdministracion(dato)"><i
                                                        class="fa fa-edit"></i></button> -->
                                        <div class="d-grid">

                                            <button class="btn btn-sm btn-primary" v-on:click="movimientos(dato)"><i
                                                    class="fa fa-eye"></i></button>

                                        </div>


                                    </td>
                                </tr>

                            </tbody>
                        </table>


                    </div>
                </div>

            </div>
        </div>

        <NuevaAdministracion ref="nuevaAdministracion" @getAdministracions="getAdministracions"></NuevaAdministracion>

        <DetalleAdministracion ref="detalleAdministracion" @editarAdministracion="editarAdministracion"
            @eliminarAdministracion="eliminarAdministracion">
        </DetalleAdministracion>


        <EditarAdministracion ref="editarAdministracion" @getAdministracions="getAdministracions">
        </EditarAdministracion>

        <EliminarAdministracion ref="eliminarAdministracion" @getAdministracions="getAdministracions">
        </EliminarAdministracion>


    </NavbarApp>
</template>

<script>

// import NavbarApp from "../adminstracion/navbar/NavbarApp";
import NavbarApp from "../Layouts/carrito/NavbarCarrito.vue";

import axios from 'axios';
axios.defaults.baseURL = process.env.API_URL;
import NuevaAdministracion from "../Administracion/NuevaAdministracion";
import DetalleAdministracion from "../Administracion/DetalleAdministracion";
import EditarAdministracion from "../Administracion/EditarAdministracion";
import EliminarAdministracion from "../Administracion/EliminarAdministracion.vue";

export default {

    name: "cajasUsuario",
    data: function () {
        return {

            showCampos: {},
            dats_categoria_tipo: null,
            adminstracions: [],
            dats_administracion: [],
            ingresos: 0,
            egresos: 0,
            saldo: 0,
            estado_administracion: 'ACTIVO',


        };
    },
    components: {
        NavbarApp, NuevaAdministracion, DetalleAdministracion, EditarAdministracion, EliminarAdministracion
    },
    created() {

        var dats_usuario    = JSON.parse(localStorage.getItem("usuarioAuth"));
        if(dats_usuario) {
            this.id_usuario = dats_usuario.id;
            this.iniciarAdministracion();
        }
        else {
            return this.$router.replace("/login");
        }
        
    },
    methods: {


        iniciarAdministracion: function () {
            this.dats_categoria_tipo = JSON.parse(this.$route.params.dats_categoria);
            this.getAdministracions();
            if (this.dats_categoria_tipo.nombre == "Cajas") {
                this.dats_categoria_tipo.slug_consulta = 'id_caja_origen';
                this.showCampos = {
                    'nombre': true,
                    'descripcion': true,
                    'estado_administracion': false
                }
            }
            if (this.dats_categoria_tipo.nombre == "Prestamos") {
                this.dats_categoria_tipo.slug_consulta = 'id_prestamo';
                this.showCampos = {
                    'id_usuario_destino': true,
                    'fecha_inicio': true,
                    'fecha_final': true,
                    'interes': true,
                    'nombre': true,
                    'descripcion': true,
                    'estado_administracion': true,
                    'is_afavor': true
                }
            }
            if (this.dats_categoria_tipo.nombre == "Proyectos") {
                this.dats_categoria_tipo.slug_consulta = 'id_proyecto';
                this.showCampos = {
                    'nombre': true,
                    'fecha_inicio': true,
                    'descripcion': true,
                    'estado_administracion': true
                }
            }
            if (this.dats_categoria_tipo.nombre == "Presupuestos") {
                this.dats_categoria_tipo.slug_consulta = 'id_presupuesto';
                this.showCampos = {
                    'id_usuario_destino': true,
                    'nombre': true,
                    'fecha_inicio': true,
                    'descripcion': true,
                    'estado_administracion': true,
                    'is_interno_aux': true
                }
            }

            if (this.dats_categoria_tipo.nombre == "Categorias Movimiento") {
                this.dats_categoria_tipo.slug_consulta = 'id_categoria';
                this.showCampos = {
                    'nombre': true,
                    'descripcion': true,
                    'estado_administracion': false,
                }
            }

        },
        getAdministracions: function () {
            axios.get('/api/getAdministracion/' + this.dats_categoria_tipo.id + '/' + this.estado_administracion).then(response => {
                this.adminstracions = response.data;
                this.ingresos = 0;
                this.egresos = 0;
                this.saldo = 0;
                this.adminstracions.forEach(admin => {
                    if (admin.ingresos == null) {
                        admin.ingresos = 0;
                    }
                    if (admin.egresos == null) {
                        admin.egresos = 0;
                    }
                    this.ingresos += parseFloat(admin.ingresos);
                    this.egresos += parseFloat(admin.egresos);
                });
                this.saldo = this.ingresos - this.egresos;

                this.$refs.cambiarOpcion.cambiarOpcion(this.dats_categoria_tipo.nombre);

            }).catch(error => {

                if (error.response) {
                    if (error.response.statusText === 'Unauthorized') {
                        return this.$router.replace("/login");
                    }
                }
                else {
                    return this.$router.replace("/login");
                }
            });
        },
        nuevaAdministracion: function () {
            this.$refs.nuevaAdministracion.iniciarNuevaAdministracion(this.dats_categoria_tipo, this.showCampos);
        },
        detalleAdministracion: function (dato) {
            this.dats_administracion = dato;
            this.$refs.detalleAdministracion.iniciarDetalleAdministracion(this.dats_administracion, this.showCampos);
        },

        editarAdministracion: function () {
            this.$refs.editarAdministracion.iniciarEditarAdministracion(this.dats_administracion, this.showCampos);
        },

        eliminarAdministracion: function () {
            this.$refs.eliminarAdministracion.iniciarEliminarAdministracion(this.dats_administracion);
        },


        movimientos: function (dato) {
            dato.slug_consulta = this.dats_categoria_tipo.slug_consulta;
            dato.dats_categoria_tipo = JSON.stringify(this.dats_categoria_tipo);

            this.$router.push({ path: "/movimientos", query: dato });
        },
        cambiarEstado: function (estado) {
            this.estado_administracion = estado;
            this.getAdministracions()
        }



    },
    mounted() {


    },
    watch: {
        '$route.params.dats_categoria': function () {
            //this.dats_categoria_tipo = JSON.parse(newId);
            this.iniciarAdministracion();
            // Aquí puedes realizar acciones adicionales si el ID cambia
        }
    },
};
</script>

<style>
.interlineado2 {
    font-size: 11px;
    line-height: 0.9rem;
    margin: 0 1px 2em;
    color: #353a3f;
}

.table_padd th {
    padding: 0.3rem;
}

.table_padd td {
    padding: 0.3rem;
}
</style>
