<template>
    <NavbarApp ref="cambiarOpcion">


        <div class="row">
            <span class="badge bg-info text-start mb-2"
                style="text-transform: uppercase; font-size: 12px; font-weight: 600; height: 25px;">MOVIMIENTOS</span>
            <div class="col-4">
                <div class="card ">
                    <div class="card-body py-1" style="font-size: 14px;">
                        <strong>{{ parseFloat(totalIngresos).toFixed(2) }}</strong> <br>
                        <span class="mb-0">Ingresos </span> <small>Bs. </small>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card ">
                    <div class="card-body py-1" style="font-size: 14px;">
                        <strong>{{ parseFloat(totalEgresos).toFixed(2) }}</strong> <br>
                        <span class="mb-0">Egresos</span> <small>Bs. </small>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card ">
                    <div class="card-body py-1" style="font-size: 14px;">
                        <strong>{{ parseFloat(totalIngresos - totalEgresos).toFixed(2) }}</strong>
                        <br>
                        <span class="mb-0">Saldo </span> <small>Bs. </small>
                    </div>
                </div>
            </div>
        </div>


        <div class="row">

            <div class="col-12">

                <div class="card">
                    <div class="card-header">
                        <div class="card-actions float-end">
                            <div class="dropdown position-relative">
                                <a href="#" data-bs-toggle="dropdown" data-bs-display="static">
                                    <i class="fa fa-list"></i>
                                </a>

                                <div class="dropdown-menu dropdown-menu-end">
                                    <a class="dropdown-item" href="#" v-on:click="generarPDFMovimientos">Reporte PDF</a>
                                    <a class="dropdown-item" href="#" v-on:click="asignarPresupuesto('INGRESO')">Asignar
                                        Presupuesto</a>
                                    <a class="dropdown-item" href="#" v-on:click="asignarPresupuesto('EGRESO')">Cerrar
                                        Presupuesto</a>
                                </div>
                            </div>
                        </div>
                        <h5 class="card-title mb-0" style="font-size: 12px; font-weight:600; text-transform: uppercase;">
                            {{ dats_administracion.nombre }}
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12  text-end d-flex">

                                <button class=" btn btn-md btn-primary" style="margin-right: 5px;" type="button"
                                    v-on:click="salirMovimientos()"><i class="fas fa-arrow-left"></i></button>

                                <input type="text" class="form-control" style="width: 45%; margin-right: 5px;"
                                    placeholder="Buscar..." id="buscador" onkeyup="buscador()">


                                <button class="btn btn-md btn-success" style="margin-right: 2px;" type="button"
                                    v-on:click="nuevoMovimiento('SI')"> Ingreso</button>

                                <button class="btn btn-md btn-danger" style="margin-left: 2px;" type="button"
                                    v-on:click="nuevoMovimiento('NO')"> Egreso</button>


                            </div>



                            <div class="col-md-12 my-3 table-responsive">
                                <table class="table table-bordered table_padd" id="tabla_a_buscar">

                                    <thead>
                                        <tr class="table table-striped">
                                            <th>Nº</th>
                                            <!-- <th>FECHA</th> -->
                                            <!-- <th>Cuenta</th> -->
                                            <th>CONCEPTO</th>
                                            <th class="text-start">ING</th>
                                            <th class="text-end">EGR</th>
                                            <th class="text-end">SAL</th>
                                            <!-- <th></th> -->
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr v-for="(dato, num) in movimientos" :key="dato">
                                            <td><small>{{ num + 1 }}</small></td>
                                            <!-- <td><small>{{ cambiarFecha(dato.fecha) }}</small></td> -->
                                            <!-- <td> <span v-if="dato.sobre_cuenta"><small>{{dato.sobre_cuenta.nombre}}</small></span></td> -->
                                            <td>
                                                <span v-on:click="detalleMovimientoCaja(dato)">
                                                    <p class="interlineado">
                                                        <span style="font-size: 11px;">{{ cambiarFecha(dato.fecha) }}</span>
                                                        <br>
                                                        {{ dato.descripcion }}
                                                    </p>
                                                </span>
                                            </td>

                                            <td class="text-start"><span v-if="dato.is_ingreso == 'SI'">
                                                    <small style="font-size: 11px;">{{ parseFloat(dato.monto).toFixed(2)
                                                    }}</small></span></td>
                                            <td class="text-end"><span v-if="dato.is_ingreso == 'NO'">
                                                    <small style="font-size: 11px;">{{ parseFloat(dato.monto).toFixed(2)
                                                    }}</small></span></td>
                                            <td class="text-end">
                                                <small style="font-size: 11px;">{{ parseFloat(dato.saldo).toFixed(2)
                                                }}</small>
                                            </td>

                                        </tr>


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div v-if="showDetalleMovimiento">
            <div class="modal" tabindex="-1">
                <div class="modal-dialog modal-md">
                    <div class="modal-content">

                        <div class="modal-body">

                            <div class="row">
                                <div class="mb-3 col-md-12" v-if="dats_movimiento">
                                    <table class="table table-striped table_padd">
                                        <tbody class="fw-semibold text-gray-800">

                                            <tr>
                                                <td>ID: </td>
                                                <td style="text-transform: uppercase;">
                                                    {{ dats_movimiento.id }}</td>
                                            </tr>
                                            <tr>
                                                <td>CATEGORIA: </td>
                                                <td style="text-transform: uppercase;">
                                                    {{ dats_movimiento.categoria.nombre }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>DESCRIPCIÓN: </td>
                                                <td style="text-transform: uppercase;">
                                                    {{ dats_movimiento.descripcion }}</td>
                                            </tr>
                                            <tr>
                                                <td>INGRESO/EGRESO: </td>
                                                <td style="text-transform: uppercase;">
                                                    <span v-if="dats_movimiento.is_ingreso == 'SI'"
                                                        style="color:green;">INGRESO</span>
                                                    <span v-if="dats_movimiento.is_ingreso == 'NO'"
                                                        style="color:red;">EGRESO</span>
                                                </td>
                                            </tr>
                                            <tr style="background-color: #ffcf8280;">
                                                <td>FECHA: </td>
                                                <td style="text-transform: uppercase;">{{ dats_movimiento.fecha }}
                                                </td>
                                            </tr>
                                            <tr style="background-color: #ffcf8280;">
                                                <td>MONTO (Bs.): </td>
                                                <td style="text-transform: uppercase;">
                                                    {{ parseFloat(dats_movimiento.monto).toFixed(2) }}</td>
                                            </tr>
                                            <tr>
                                                <td>Nº COMPROBANTE: </td>
                                                <td style="text-transform: uppercase;">
                                                    {{ dats_movimiento.correlativo }}</td>
                                            </tr>

                                            <tr>
                                                <td>TRANSFERENCIA: </td>
                                                <td style="text-transform: uppercase;">
                                                    {{ dats_movimiento.is_transferencia }}
                                                </td>
                                            </tr>




                                        </tbody>
                                    </table>
                                </div>



                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary"
                                v-on:click="showDetalleMovimiento = false">Cerrar</button>

                            <button type="button" class="btn btn-danger" v-on:click="eliminarMovimiento()">Eliminar</button>

                            <button type="button" class="btn btn-success" v-on:click="editarMovimiento()">Editar</button>

                        </div>


                    </div>
                </div>
            </div>
        </div>

        <EditarMovimiento ref="editarMovimientoModal" @reloadMovimientosUpdate="reloadMovimientosUpdate">
        </EditarMovimiento>
        <NuevoMovimiento ref="nuevoMovimientoModal" @reloadMovimientos="reloadMovimientos"></NuevoMovimiento>
        <EliminarMovimiento ref="eliminarMovimientoModal" @reloadMovimientosDelete="reloadMovimientosDelete">
        </EliminarMovimiento>

        <NuevaTranferenciaModal ref="nuevaTransferenciaModal" @reloadMovimientos="reloadMovimientos">
        </NuevaTranferenciaModal>

    </NavbarApp>
</template>
<script>


// import NavbarApp from "../adminstracion/navbar/NavbarApp";
import NavbarApp from "../Layouts/carrito/NavbarCarrito.vue";



import axios from 'axios';
axios.defaults.baseURL = process.env.API_URL;
import NuevoMovimiento from "../M_Movimiento/NuevoMovimiento.vue";
import EditarMovimiento from "../M_Movimiento/EditarMovimiento.vue";
import EliminarMovimiento from "../M_Movimiento/EliminarMovimiento.vue";
import NuevaTranferenciaModal from "../M_Transferencia/NuevaTranferenciaModal";

export default {
    name: "MovimientosCaja",
    //props: ['datsProyecto'],
    data: function () {
        return {

            showDetalleMovimiento: false,
            totalIngresos: 0,
            totalEgresos: 0,
            dats_administracion: [],
            movimientos: [],
            dats_movimiento: null,

            showCamposMovimiento: {},
            id_cat_prestamo_default:'',
        };
    },
    components: {
        NavbarApp, NuevoMovimiento, EditarMovimiento, EliminarMovimiento, NuevaTranferenciaModal
    },
    created: function () {
        
        this.dats_administracion = this.$route.query;
        this.dats_categoria_tipo = JSON.parse(this.dats_administracion.dats_categoria_tipo);
        
        var dats_usuario    = JSON.parse(localStorage.getItem("usuarioAuth"));
        if(dats_usuario) {
            this.id_cat_prestamo_default = dats_usuario.id_prestamo_default;

            // var dats_categoria_tipo = JSON.parse(this.dats_administracion.dats_categoria_tipo);

            if (this.dats_categoria_tipo.nombre == "Cajas") {
                this.showCamposMovimiento = {
                    "id_categoria": true,
                    "id_proyecto": true,
                    "id_prestamo": true,
                    "id_caja_origen_auxiliar": this.dats_administracion.id,
                    "id_proyecto_auxiliar": "",
                    "id_prestamo_auxiliar": "",
                    "id_categoria_auxiliar": ""
                }
            }
            if (this.dats_categoria_tipo.nombre == "Proyectos") {
                this.showCamposMovimiento = {
                    "id_caja_origen": true,
                    "id_categoria": true,

                    "id_caja_origen_auxiliar": "",
                    "id_proyecto_auxiliar": this.dats_administracion.id,
                    "id_prestamo_auxiliar": "",
                    "id_categoria_auxiliar": ""
                }
            }
            if (this.dats_categoria_tipo.nombre == "Prestamos") {
                this.showCamposMovimiento = {
                    "id_caja_origen": true,

                    "id_caja_origen_auxiliar": "",
                    "id_proyecto_auxiliar": "",
                    "id_prestamo_auxiliar": this.dats_administracion.id,
                    "id_categoria_auxiliar": this.id_cat_prestamo_default

                }
            }
            if (this.dats_categoria_tipo.nombre == "Presupuestos") {
                this.showCamposMovimiento = {
                    "id_proyecto": true,
                    "id_categoria": true,
                    "id_caja_origen_auxiliar": this.dats_administracion.id,
                    "id_proyecto_auxiliar": "",
                    "id_prestamo_auxiliar": "",
                    "id_categoria_auxiliar": ""

                }
                this.dats_administracion.slug_consulta = 'id_caja_origen';
            }


            this.iniciarMovimientos();
        }
        else {
            return this.$router.replace("/login");
        }

    },
    methods: {

        iniciarMovimientos: function () {
            this.getMovimientos();

        },
        getMovimientos: function () {
            axios.get('/api/getMovimientos/' + this.dats_administracion.slug_consulta + '/' + this.dats_administracion.id).then(response => {
                this.movimientos = response.data;

                this.totalIngresos = 0;
                this.totalEgresos = 0;

                this.movimientos.forEach(movimiento => {
                    const monto = parseFloat(movimiento.monto);
                    if (movimiento.is_ingreso === "SI") {
                        this.totalIngresos += monto;
                    } else {
                        this.totalEgresos += monto;

                    }

                    this.totalIngresos += parseFloat(movimiento.ingreso_ultimo);
                    this.totalEgresos += parseFloat(movimiento.egreso_ultimo);



                });
                this.$refs.cambiarOpcion.cambiarOpcion(this.dats_categoria_tipo.nombre);

            }).catch(error => {

                //  console.log(error);
                if (error.response) {
                    if (error.response.statusText === 'Unauthorized') {
                        return this.$router.replace("/login");
                    }
                }
                else {
                    return this.$router.replace("/login");
                }
            });
        },
        nuevoMovimiento: function (is_ingreso) {
            this.$refs.nuevoMovimientoModal.iniciarNuevoMovimiento(this.dats_administracion, is_ingreso, this.showCamposMovimiento);
        },

        asignarPresupuesto: function (is_ingreso) {
            this.$refs.nuevaTransferenciaModal.iniciarNuevaTransferencia(this.dats_administracion, is_ingreso);
        },

        detalleMovimientoCaja: function (movimiento) {
            this.dats_movimiento = movimiento;
           // console.log(this.dats_movimiento);
            this.showDetalleMovimiento = true;
        },

        editarMovimiento: function () {
            this.showDetalleMovimiento = false;
            this.$refs.editarMovimientoModal.iniciarEditarMovimiento(this.dats_movimiento, this.showCamposMovimiento);
        },

        updateMovimiento: function () {
            axios.post('/api/updateMovimiento', this.dats_movimiento).then(response => {
                response;

                this.iniciarMovimientos();
            });
        },

        reloadMovimientos: function () {
            this.getMovimientos();
        },
        reloadMovimientosUpdate: function () {
            this.getMovimientos();
        },
        reloadMovimientosDelete: function () {
            this.getMovimientos();
        },

        eliminarMovimiento: function () {
            this.showDetalleMovimiento = false;
            this.$refs.eliminarMovimientoModal.iniciarEliminarMovimiento(this.dats_movimiento);
        },
        generarPDFMovimientos: function () {
            window.open('https://finans.sienegan.com/generarReporteMovimiento/' + this.dats_administracion.id);
        },
        cambiarFecha: function (fecha_convertida) {
            var fecha = fecha_convertida;
            var dateObj = new Date(fecha + 'T00:00:00Z');
            var dia = dateObj.getUTCDate();
            var mes = dateObj.getUTCMonth() + 1;
            var anio = dateObj.getUTCFullYear();
            var fechaFormateada = dia + '/' + mes + '/' + anio;
            return fechaFormateada;
        },
        salirMovimientos: function () {
            var dato = this.dats_administracion.dats_categoria_tipo;
            this.$router.push({ name: 'administracion', params: { dats_categoria: dato } });
        },




    },
    mounted() {
    },
    computed: {
    },
};
</script>
<style>
.interlineado {
    font-size: 11.6px;
    line-height: 0.9rem;
    margin: 0 1px 2em;
    color: #353a3f;
}
</style>
