<template>
    <div v-if="showEditarMovimiento">
        <div class="modal" tabindex="-1">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <form v-on:submit.prevent="updateMovimiento()">
                        <div class="modal-body" style="color:black;">
                            <p style="text-transform:uppercase;" class="align-self-center text-center">
                                <b>{{ titulo_modal }}</b>
                            </p>

                            <div class="row">



                                <div class="mb-1 col-12"   v-if="showCamposMovimiento.id_caja_origen">
                                    <label for="grado_confianza">Caja </label>
                                    <select class="form-select" v-model="dats_movimiento.id_caja_origen"
                                      
                                        :style="[dats_movimiento.id_caja_origen == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                        required="required">
                                        <option value="null">Seleccione una opción</option>
                                        <option v-for="caja in cajas" :value="caja.id" :key="caja">{{ caja.nombre }}
                                        </option>
                                    </select>
                                </div>



                                <div class="mb-1 col-12"      v-if="showCamposMovimiento.id_proyecto">
                                    <label for="grado_confianza">Proyectos </label>
                                    <select class="form-select" v-model="dats_movimiento.id_proyecto"
                               
                                        :style="[dats_movimiento.id_proyecto == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]">
                                        <option value="null">Sin Proyecto</option>
                                        <option v-for="proyecto in proyectos" :value="proyecto.id" :key="proyecto">{{
                                            proyecto.nombre }}
                                        </option>
                                    </select>
                                </div>


                                <div class="mb-1 col-12"     v-if="showCamposMovimiento.id_prestamo">
                                    <label for="grado_confianza">Prestamos </label>
                                    <select class="form-select" v-model="dats_movimiento.id_prestamo"
                                    
                                        :style="[dats_movimiento.id_prestamo == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]">
                                        <option value="null">Sin Prestamo</option>
                                        <option v-for="prestamo in prestamos" :value="prestamo.id" :key="prestamo">{{
                                            prestamo.nombre }}
                                        </option>
                                    </select>
                                </div>



                                <div class="mb-1 col-12"   v-if="showCamposMovimiento.id_presupuesto">
                                    <label for="grado_confianza">Presupuestos </label>
                                    <select class="form-select" v-model="dats_movimiento.id_presupuesto"
                                  
                                        :style="[dats_movimiento.id_presupuesto == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]">
                                        <option value="null">Sin Presupuesto</option>
                                        <option v-for="presupuesto in presupuestos" :value="presupuesto.id"
                                            :key="presupuesto">{{
                                                presupuesto.nombre }}
                                        </option>
                                    </select>
                                </div>


                                <div class="mb-1 col-6">
                                    <label for="id_caja">Ingreso/Egreso</label>
                                    <select class="form-select" v-model="dats_movimiento.is_ingreso"
                                        :style="[dats_movimiento.is_ingreso == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                        required>
                                        <option value="SI">INGRESO</option>
                                        <option value="NO">EGRESO</option>
                                    </select>
                                </div>

                                <div class="mb-1 col-6">
                                    <label for="id_caja">Fecha</label>
                                    <input class="form-control Float"
                                        :style="[dats_movimiento.fecha == '' ? { 'background-color': 'white' } : { 'background-color': '#ebfbe9' }]"
                                        type="date" v-model="dats_movimiento.fecha" required="required">
                                </div>

                                <div class="mb-1 col-12"        v-if="showCamposMovimiento.id_categoria">
                                    <label for="id_caja">Categoria</label>
                                    <select class="form-select" v-model="dats_movimiento.id_categoria"
                             
                                        :style="[dats_movimiento.id_categoria == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                        required>
                                        <option value="">Seleccione una Categoria</option>
                                        <option v-for="categoria in categorias_movimiento" :key="categoria"
                                            :value="categoria.id">
                                            {{ categoria.nombre }}
                                        </option>
                                    </select>
                                </div>

                                <div class="mb-1 col-12">
                                    <label for="id_caja">Descripción</label>
                                    <input class="form-control Float"
                                        :style="[dats_movimiento.descripcion == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                        type="text" placeholder="Descripción" v-model="dats_movimiento.descripcion"
                                        required="required">
                                </div>


                                <div class="mb-1 col-12">
                                    <label for="id_caja">Monto (Bs.)</label>
                                    <input class="form-control Float" type="number"
                                        :style="[dats_movimiento.monto == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                        placeholder="Monto" step="any" v-model="dats_movimiento.monto"
                                        required="required">

                                </div>

                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-md btn-secondary"
                                v-on:click="showEditarMovimiento = false">Cerrar</button>
                            <button type="submit" class="btn btn-md btn-primary">Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
axios.defaults.baseURL = process.env.API_URL;


export default {
    name: "nuevoMovimiento",
    data: function () {
        return {
            showEditarMovimiento: false,
            titulo_modal: "",

            categorias_movimiento: [],
            cajas: [],
            proyectos: [],
            prestamos: [],
            presupuestos: [],

            dats_movimiento: {}
        };
    },
    components: {

    },
    created() {
        // this.getCategoriasMovimientos();
        // this.getProyectos();
        // this.getPrestamos();
        // this.getCajas();
    },
    methods: {


        iniciarEditarMovimiento: async function (dats_movimiento,showCamposMovimiento) {

            this.showCamposMovimiento = showCamposMovimiento;

            if (this.showCamposMovimiento.id_caja_origen) {
                this.cajas = await this.getAdministracions(this.$store.state.opcion_cajas.id);
            }
            if (this.showCamposMovimiento.id_proyecto) {
                this.proyectos = await this.getAdministracions(this.$store.state.opcion_proyectos.id);
            }
            if (this.showCamposMovimiento.id_prestamo) {
                this.prestamos = await this.getAdministracions(this.$store.state.opcion_prestamos.id);
            }
            if (this.showCamposMovimiento.id_categoria) {
                this.categorias_movimiento = await this.getAdministracions(this.$store.state.opcion_categorias.id);
            }

            // if (this.cajas.length == 0) {
            //     this.cajas = await this.getAdministracions(this.$store.state.opcion_cajas.id);
            //     this.proyectos = await this.getAdministracions(this.$store.state.opcion_proyectos.id);
            //     this.prestamos = await this.getAdministracions(this.$store.state.opcion_prestamos.id);
            //     this.presupuestos = await this.getAdministracions(this.$store.state.opcion_presupuestos.id);
            //     this.categorias_movimiento = await this.getAdministracions(this.$store.state.opcion_categorias.id);
            // }

            this.dats_movimiento = dats_movimiento;
            this.showEditarMovimiento = true;
            this.titulo_modal = 'EDITAR DATOS DE MOVIMIENTO';
        },


        getAdministracions: async function (id_categoria_tipo) {
            return axios.get('/api/getAdministracion/' + id_categoria_tipo+'/ACTIVO').then(response => {
                return response.data;
            });
        },


        updateMovimiento: function () {
            axios.post('/api/updateMovimiento', this.dats_movimiento).then(() => {
                this.showEditarMovimiento = false;
                // return console.log(response.data);
                this.$emit('reloadMovimientosUpdate');
            })
        },
        fechaActualYMD() {
            let fechaHora = new Date();
            let anio = fechaHora.getFullYear();
            let mes = (fechaHora.getMonth() + 1).toString().padStart(2, '0');
            let dia = fechaHora.getDate().toString().padStart(2, '0');
            let fechaHoraFormateada = anio + '-' + mes + '-' + dia;
            return fechaHoraFormateada;
        },

    },
    mounted() {


    },
};
</script>

<style>
.table_padd th {
    padding: 0.2rem;
}

.table_padd td {
    padding: 0.2rem;
}
</style>