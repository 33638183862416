<template>
    <div v-if="showNuevaAdministracion">
        <div class="modal" tabindex="-1">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <form v-on:submit.prevent="storeAdministracion()">
                        <div class="modal-body" style="color:black;">
                            <p style="text-transform:uppercase;" class="align-self-center text-center">
                                <b>{{ titulo_modal }}</b>
                            </p>

                            <div class="row">



                                <div class="mb-1 col-12" v-if="showCampos.id_categoria_tipo">
                                    <label for="grado_confianza">Categoria Tipo </label>
                                    <select class="form-select" v-model="new_administracion.id_categoria_tipo" 
                                    :style="[new_administracion.id_categoria_tipo == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                    required="required" style="text-transform: capitalize;">
                                        <option value="">Seleccione una opción</option>
                                        <option v-for="categoria in categorias_tipo" :value="categoria.id" :key="categoria">{{ categoria.nombre }}
                                        </option>
                                    </select>
                                </div>

                                <div class="mb-1 col-12" v-if="showCampos.id_usuario_origen">
                                    <label for="grado_confianza">Usuario Origen</label>
                                    <select class="form-select" v-model="new_administracion.id_usuario_origen" 
                                    :style="[new_administracion.id_usuario_origen == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                    required="required" style="text-transform: capitalize;">
                                        <option value="">Seleccione una opción</option>
                                        <option v-for="usuario in usuarios" :value="usuario.id" :key="usuario">{{ usuario.name }}
                                        </option>
                                    </select>
                                </div>

                                <div class="mb-1 col-12" v-if="showCampos.id_usuario_destino">
                                    <label for="grado_confianza">Usuario Destino</label>
                                    <select class="form-select" v-model="new_administracion.id_usuario_destino" 
                                    :style="[new_administracion.id_usuario_destino == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                    required="required" style="text-transform: capitalize;">
                                        <option value="">Seleccione una opción</option>
                                        <option v-for="usuario in usuarios" :value="usuario.id" :key="usuario">{{ usuario.name }}
                                        </option>
                                    </select>
                                </div>

                                <div class="mb-1 col-12" v-if="showCampos.nombre">
                                    <label for="id_caja">Nombre</label>
                                    <input class="form-control Float"
                                        :style="[new_administracion.nombre == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                        type="text" placeholder="Nombre" v-model="new_administracion.nombre"
                                        required="required">
                                </div>

                                <div class="mb-1 col-12" v-if="showCampos.descripcion">
                                    <label for="id_caja">Descripción</label>
                                    <input class="form-control Float"
                                        :style="[new_administracion.descripcion == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                        type="text" placeholder="Descripción" v-model="new_administracion.descripcion"
                                        >
                                </div>

                                <div class="mb-1 col-12" v-if="showCampos.fecha_inicio">
                                    <label for="id_caja">Fecha Inicio</label>
                                    <input class="form-control Float"
                                        :style="[new_administracion.fecha_inicio == '' ? { 'background-color': 'white' } : { 'background-color': '#ebfbe9' }]"
                                        type="date" v-model="new_administracion.fecha_inicio" required="required">
                                </div>
                                <div class="mb-1 col-12" v-if="showCampos.fecha_final">
                                    <label for="id_caja">Fecha Final</label>
                                    <input class="form-control Float"
                                        :style="[new_administracion.fecha_final == '' ? { 'background-color': 'white' } : { 'background-color': '#ebfbe9' }]"
                                        type="date" v-model="new_administracion.fecha_final">
                                </div>

                            
                                <div class="mb-1 col-12" v-if="showCampos.interes">
                                    <label for="id_caja">Interes (%)</label>
                                    <input class="form-control Float" type="number"
                                        :style="[new_administracion.interes == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                        placeholder="Interes" step="any" v-model="new_administracion.interes" >

                                </div>



                               
                                <div class="mb-1 col-12" v-if="showCampos.is_afavor">
                                    <label for="id_caja">A FAVOR</label>
                                    <select class="form-select" v-model="new_administracion.is_afavor"
                                        :style="[new_administracion.is_afavor == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                        required>
                                        <option value="">Seleccione una opción</option>
                                        <option value="NO">YO PRESTANDOME</option>
                                        <option value="SI">YO PRESTANDO</option>
                                    </select>
                                </div>
                                
                                <div class="mb-1 col-12" v-if="showCampos.is_interno_aux">
                                    <label for="id_caja">Presupuesto INTERNO/EXTERNO</label>
                                    <select class="form-select" v-model="new_administracion.is_afavor"
                                        :style="[new_administracion.is_afavor == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                        required>
                                        <option value="">Seleccione una opción</option>
                                        <option value="NO">PRESUPUESTO EXTERNO</option>
                                        <option value="SI">PRESUPUESTO INTERNO</option>
                                    </select>
                                </div>

                                <div class="mb-1 col-12" v-if="showCampos.estado_administracion">
                                    <label for="id_caja">Estado</label>
                                    <select class="form-select" v-model="new_administracion.estado_administracion"
                                        :style="[new_administracion.estado_administracion == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                        required>
                                        <option value="">Seleccione una opción</option>
                                        <option value="ACTIVO">ACTIVO</option>
                                        <option value="CONCLUIDO">CONCLUIDO</option>
                                    </select>
                                </div>

                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary"
                                v-on:click="showNuevaAdministracion = false">Cerrar</button>
                            <button type="submit" class="btn btn-primary">Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

import axios from 'axios';
axios.defaults.baseURL = process.env.API_URL;

export default {
    name: "nuevaAdministracion",
    data: function () {
        return {
            showNuevaAdministracion: false,
            dats_categoria_tipo:[],
            titulo_modal: "",
            new_administracion:{},
            usuarios:[],
            categorias_tipo:[],
            showCampos:[]
        };
    },
    components: {

    },
    created() {
    },
    methods: {

        iniciarFormulario: function () {
            this.new_administracion = {
                "id_categoria_tipo": "",
                "id_usuario_origen": "",
                "id_usuario_destino": "",
                "nombre": "",
                "descripcion": "",
                "fecha_inicio": this.fechaActualYMD(),
                "fecha_final": "",
                "interes": "0",
                "estado_administracion": "ACTIVO",
                "is_afavor":""
            }
        },

        iniciarNuevaAdministracion: function (dats_categoria_tipo,showCampos) {
            this.dats_categoria_tipo = dats_categoria_tipo;
            this.showCampos          = showCampos;
            this.iniciarFormulario();
            // this.getCategoriaTipo();
            if(showCampos.id_usuario_destino) {
                this.getUsuarios();
            }
        
            this.showNuevaAdministracion = true;
            this.titulo_modal = 'AGREGAR '+this.dats_categoria_tipo.nombre;
            this.new_administracion.id_categoria_tipo  = this.dats_categoria_tipo.id;
            this.new_administracion.id_usuario_origen  = this.dats_categoria_tipo.id_usuario;
            this.new_administracion.id_usuario_destino = this.dats_categoria_tipo.id_usuario;
        },
       
        getCategoriaTipo: function () {
            axios.get('/api/categoria-tipos').then(response => {
                this.categorias_tipo = response.data;
            });
        },

        getUsuarios: function () {
            axios.get('/api/getUsuariosUsuario/'+this.dats_categoria_tipo.id_usuario).then(response => {
                this.usuarios = response.data;
            });
        },
      
        storeAdministracion: function () {
            if(this.new_administracion.descripcion == '') {
                this.new_administracion.descripcion = this.new_administracion.nombre;
            }
            if(this.new_administracion.is_afavor == '') {
                this.new_administracion.is_afavor = 'SI';
            }
            axios.post('/api/storeAdministracion', this.new_administracion).then(() => {
                this.showNuevaAdministracion = false;
                this.$emit('getAdministracions');
            });
        },

        fechaActualYMD() {
            let fechaHora = new Date();
            let anio = fechaHora.getFullYear();
            let mes = (fechaHora.getMonth() + 1).toString().padStart(2, '0');
            let dia = fechaHora.getDate().toString().padStart(2, '0');
            let fechaHoraFormateada = anio + '-' + mes + '-' + dia;
            return fechaHoraFormateada;
        },

    },
    mounted() {


    },
};
</script>