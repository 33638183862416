<template>
    <div v-if="showNuevoMovimiento">
        <div class="modal" tabindex="-1">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <form v-on:submit.prevent="storeMovimiento()">
                        <div class="modal-body" style="color:black;">
                            <center>
                                <!-- <b style="text-transform:uppercase; font-weight: bold;">{{ titulo_modal }}</b> -->
                                <p v-html="titulo_modal" style="text-transform: uppercase; font-size:12px"></p>
                            </center>

                            <div class="row my-2">

                                <div class="mb-1 col-12" v-if="showCamposMovimiento.id_caja_origen">
                                    <label for="grado_confianza">Caja </label>
                                    <select class="form-select" v-model="new_movimiento.id_caja_origen"
                                        :style="[new_movimiento.id_caja_origen == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                        required="required" style="text-transform: capitalize;">
                                        <option value="">Seleccione una opción</option>
                                        <option v-for="caja in cajas" :value="caja.id" :key="caja">{{ caja.nombre }}
                                        </option>
                                    </select>
                                </div>


                                <div class="mb-1 col-12" v-if="showCamposMovimiento.id_proyecto">
                                    <label for="grado_confianza">Proyectos </label>
                                    <select class="form-select" v-model="new_movimiento.id_proyecto"
                                        :style="[new_movimiento.id_proyecto == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]">
                                        <option value="">Sin Proyecto</option>
                                        <option v-for="proyecto in proyectos" :value="proyecto.id" :key="proyecto">{{
                                            proyecto.nombre }}
                                        </option>
                                    </select>
                                </div>


                                <div class="mb-1 col-12" v-if="showCamposMovimiento.id_prestamo">
                                    <label for="grado_confianza">Prestamos </label>
                                    <select class="form-select" v-model="new_movimiento.id_prestamo"
                                        :style="[new_movimiento.id_prestamo == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]">
                                        <option value="">Sin Prestamo</option>
                                        <option v-for="prestamo in prestamos" :value="prestamo.id" :key="prestamo">{{
                                            prestamo.nombre }}
                                        </option>
                                    </select>
                                </div>

                                <div class="mb-1 col-12">
                                    <label for="id_caja">Fecha</label>
                                    <input class="form-control Float"
                                        :style="[new_movimiento.fecha == '' ? { 'background-color': 'white' } : { 'background-color': '#ebfbe9' }]"
                                        type="date" v-model="new_movimiento.fecha" required="required">
                                </div>

                                <div class="mb-1 col-12" v-if="showCamposMovimiento.id_categoria">
                                    <label for="id_caja">Categoria</label>
                                    <select class="form-select" v-model="new_movimiento.id_categoria"
                                        :style="[new_movimiento.id_categoria == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                        required>
                                        <option value="">Seleccione una Categoria</option>
                                        <option v-for="categoria in categorias_movimiento" :key="categoria"
                                            :value="categoria.id">
                                            {{ categoria.nombre }}
                                        </option>
                                    </select>
                                </div>

                                <div class="mb-1 col-12">
                                    <label for="id_caja">Descripción</label>
                                    <input class="form-control Float"
                                        :style="[new_movimiento.descripcion == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                        type="text" placeholder="Descripción" v-model="new_movimiento.descripcion"
                                        required="required">
                                </div>


                                <div class="mb-1 col-12">
                                    <label for="id_caja">Monto (Bs.)</label>
                                    <input class="form-control Float" type="number"
                                        :style="[new_movimiento.monto == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                        placeholder="Monto" step="any" v-model="new_movimiento.monto" required="required">

                                </div>



                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary"
                                v-on:click="showNuevoMovimiento = false">Cerrar</button>
                            <button type="submit" class="btn btn-primary">Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
axios.defaults.baseURL = process.env.API_URL;
import { Notyf } from 'notyf';
const notyf = new Notyf();

export default {
    name: "nuevoMovimiento",
    data: function () {
        return {
            showNuevoMovimiento: false,
            titulo_modal: "",
            new_movimiento: {},

            cajas: [],
            proyectos: [],
            prestamos: [],
            presupuestos: [],
            categorias_movimiento: [],

            showCamposMovimiento: [],
        };
    },
    components: {

    },
    created() {
    },
    methods: {

        iniciarFormulario: function () {
            this.new_movimiento = {
                "id_caja_origen": "",
                "id_caja_destino": "",
                "id_categoria": "",
                "id_proyecto": "",
                "id_prestamo": "",
                "id_presupuesto": "",
                "id_tipo_comprobante": "18",
                "fecha": this.fechaActualYMD(),
                "correlativo": "0",
                "descripcion": "",
                "monto": "",
                "is_ingreso": "NO",
                "is_transferencia": "",
                "is_pagado": "SI"
            }
        },

        iniciarNuevoMovimiento: async function (dats_administracion, is_ingreso, showCamposMovimiento) {

            this.showCamposMovimiento = showCamposMovimiento;
            this.iniciarFormulario();
            this.new_movimiento[dats_administracion.slug_consulta] = dats_administracion.id;

            this.new_movimiento.id_caja_destino = dats_administracion.id;
            this.new_movimiento.is_ingreso = is_ingreso;

            if (this.showCamposMovimiento.id_caja_origen) {
                this.cajas = await this.getAdministracions(this.$store.state.opcion_cajas.id);
            }
            if (this.showCamposMovimiento.id_proyecto) {
                this.proyectos = await this.getAdministracions(this.$store.state.opcion_proyectos.id);
            }
            if (this.showCamposMovimiento.id_prestamo) {
                this.prestamos = await this.getAdministracions(this.$store.state.opcion_prestamos.id);
            }
            if (this.showCamposMovimiento.id_categoria) {
                this.categorias_movimiento = await this.getAdministracions(this.$store.state.opcion_categorias.id);
            }

            this.new_movimiento.id_categoria = showCamposMovimiento.id_categoria_auxiliar;

            this.showNuevoMovimiento = true;
            var ingreso_egreso = 'EGRESO';
            this.titulo_modal = 'NUEVO <b style="color:red; font-weight: bold; font-size:14px;">(' + ingreso_egreso + ')</b> EN: <br> ' + dats_administracion.nombre;
            if (is_ingreso == 'SI') {
                ingreso_egreso = 'INGRESO';
                this.titulo_modal = 'NUEVO <b style="color:green; font-weight: bold; font-size:14px;">(' + ingreso_egreso + ')</b> EN: <br> ' + dats_administracion.nombre;
            }

        },


        getAdministracions: async function (id_categoria_tipo) {
            return axios.get('/api/getAdministracion/' + id_categoria_tipo + '/ACTIVO').then(response => {
                return response.data;
            });
        },

        storeMovimiento: function () {
            this.new_movimiento.is_transferencia = 'NO';
            axios.post('/api/storeMovimiento', this.new_movimiento).then(() => {
                // this.showNuevoMovimiento = false;
                this.notificacionSuccess('Registrado Correctamente');
                this.new_movimiento.descripcion = '';
                this.new_movimiento.monto = '';
                this.$emit('reloadMovimientos');

            });
        },


        fechaActualYMD() {
            let fechaHora = new Date();
            let anio = fechaHora.getFullYear();
            let mes = (fechaHora.getMonth() + 1).toString().padStart(2, '0');
            let dia = fechaHora.getDate().toString().padStart(2, '0');
            let fechaHoraFormateada = anio + '-' + mes + '-' + dia;
            return fechaHoraFormateada;
        },
        notificacionSuccess: function (texto) {
            notyf.success({
                duration: 1000,
                position: {
                    x: 'right',
                    y: 'top',
                },
                message: texto
            });
        },

    },
    mounted() {


    },
};
</script>

<style>
.table_padd th {
    padding: 0.2rem;
}

.table_padd td {
    padding: 0.2rem;
}
</style>