<template>
    <div v-if="showDetallePersona">
        <div class="modal" tabindex="-1">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <form @submit.prevent="updatePersona()">
                        <div class="modal-body" style="color:black;">
                            <p style="text-transform:uppercase;" class="align-self-center text-center">
                                <b style="font-weight: bold;">DETALLE</b>
                            </p>
                            <div class="row">

                                <table class="table table-striped table_padd">
                                    <tbody class="fw-semibold text-gray-800">

                                        <tr>
                                            <td><b style="font-weight: bold;">ID: </b></td>
                                            <td style="text-transform: uppercase;">
                                                {{ dats_persona.id }}</td>
                                        </tr>
                                        <tr>
                                            <td><b style="font-weight: bold;">NOMBRE: </b></td>
                                            <td style="text-transform: uppercase;">
                                                {{ dats_persona.name }}</td>
                                        </tr>
                                        <tr>
                                            <td><b style="font-weight: bold;">EMAIL: </b></td>
                                            <td style="text-transform: lowercase;">
                                                {{ dats_persona.email }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><b style="font-weight: bold;">CELULAR 1:</b></td>
                                            <td style="text-transform: uppercase;">
                                                {{ dats_persona.celular }}</td>
                                        </tr>
                                        <tr>
                                            <td><b style="font-weight: bold;">CELULAR 2:</b> </td>
                                            <td style="text-transform: uppercase;">
                                                {{ dats_persona.celular2 }}</td>
                                        </tr>
                                        <tr>
                                            <td><b style="font-weight: bold;">DIRECCION: </b></td>
                                            <td style="text-transform: uppercase;">
                                                {{ dats_persona.direccion }}</td>
                                        </tr>
                                        <tr>
                                            <td><b style="font-weight: bold;">EMPRESA: </b></td>
                                            <td style="text-transform: uppercase;">
                                                {{ dats_persona.empresa }}</td>
                                        </tr>
                                        <tr>
                                            <td><b style="font-weight: bold;">DESCRIPCION: </b></td>
                                            <td style="text-transform: uppercase;">
                                                {{ dats_persona.descripcion }}</td>
                                        </tr>

      

                                    </tbody>
                                </table>

                              

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary"
                                v-on:click="showDetallePersona = false">Cerrar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


import axios from 'axios';
axios.defaults.baseURL = process.env.API_URL;

export default {
    name: "proyectosUsuario",
    data: function () {
        return {
            showDetallePersona: false,
            dats_persona: [],
        };
    },
    components: {

    },
    created() {

    },
    methods: {

        iniciarDetallePersona: function (dato) {
            this.dats_persona = dato;
            this.showDetallePersona = true;
        },



    },
    mounted() {


    },
};
</script>
