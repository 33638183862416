<template>
    <div v-if="showEliminarMovimiento">
        <div class="modal" tabindex="-1">
            <div class="modal-dialog modal-sm">
                <div class="modal-content">
                    <form v-on:submit.prevent="deleteMovimiento()">
                        <div class="modal-body" style="color:black;">
                            <p style="text-transform:uppercase;" class="align-self-center text-center">
                                <b>{{ titulo_modal }}</b>
                            </p>

                            <div class="row">

                                <center>
                                    ¿esta seguro de eliminar este movimiento?
                                </center>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary"
                                v-on:click="showEliminarMovimiento = false">Cerrar</button>
                            <button type="submit" class="btn btn-primary">Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
axios.defaults.baseURL = process.env.API_URL;


export default {
    name: "nuevoMovimiento",
    data: function () {
        return {
            showEliminarMovimiento: false,
            titulo_modal: "",
            dats_movimiento: {}
        };
    },
    components: {

    },
    created() {

    },
    methods: {

        iniciarEliminarMovimiento: function (dats_movimiento) {
            this.dats_movimiento = dats_movimiento;
            this.showEliminarMovimiento = true;
            this.titulo_modal = 'ELIMINAR MOVIMIENTO';
        },

        deleteMovimiento: function () {
            this.dats_movimiento.estado = 0;
            axios.post('/api/updateMovimiento', this.dats_movimiento).then(() => {
                this.showEliminarMovimiento = false;
                this.$emit('reloadMovimientosDelete');
            })
        },
       

    },
    mounted() {


    },
};
</script>

<style>
.table_padd th {
    padding: 0.2rem;
}

.table_padd td {
    padding: 0.2rem;
}
</style>