<template>
    <div class="main d-flex justify-content-center w-100">
        <main class="content d-flex p-0">
            <div class="container d-flex flex-column">
                <div class="row h-100">
                    <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                        <div class="d-table-cell align-middle">

                            <div class="text-center mt-2">
                                <h1 class="h2" style="color: black;">INICIAR SESION
                                </h1>
                            </div>

                            <div class="card">
                                <div class="card-body">
                                    <div class="m-sm-1">
                                        <div class="text-center">
                                            <!-- <img src="" alt="Chris Wood"
                                                class="img-fluid rounded-circle" width="132" height="132" /> -->
                                        </div>

                                        <form @submit.prevent="loginAuth">
                                            <div class="mb-3">
                                                <label class="form-label">Email</label>
                                                <input class="form-control form-control-lg" type="email"
                                                    placeholder="Enter your email" v-model="form.email" required />
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">Password</label>
                                                <input class="form-control form-control-lg" type="password"
                                                    placeholder="Enter your password" v-model="form.password" required />

                                            </div>

                                            <div class="text-center mt-3">
                                                <button type="submit" class="btn btn-lg btn-primary">INICIAR SESION</button>

                                            </div>
                                            <div class="text-center mt-3">
                                                ¿No tiene una cuenta?


                                                <router-link to="/registrate" exact>
                                                    Regístrese
                                                </router-link>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<style>
.h2 {
    color: white;
}
</style>

<script>

import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.baseURL = process.env.API_URL;

export default {

    data: () => ({

        // usuario: null,
        form: {
            email: '',
            password: ''
        }

    }),
    async created() {

        // await this.$store.dispatch("getUsuarioAutenticado");

        var dats_usuario = JSON.parse(localStorage.getItem("usuarioAuth"));
        if (dats_usuario) {

            var dato = JSON.stringify(dats_usuario.categoria_tipos[0]);
            this.$router.push({ name: 'administracion', params: { dats_categoria: dato } });

        }
  

        // if (localStorage.getItem("usuarioAuth") > 0) {

        //     if (this.$store.state.usuario) {
        //         var dato = this.$store.state.usuario.categoria_tipos[0];
        //         dato = JSON.stringify(dato);
        //         this.$router.push({ name: 'administracion', params: { dats_categoria: dato } });
        //     }
        // }

    },
    methods: {
        async loginAuth() {
            await this.$store.dispatch('login', this.form);
            if (this.$store.state.usuario) {
                // var dato = {
                //     'id': 1,
                //     'nombre': 'Cajas',
                //     'id_usuario': 1
                // }
                var dato = this.$store.state.usuario.categoria_tipos[0];
                dato = JSON.stringify(dato);
                return this.$router.push({ name: 'administracion', params: { dats_categoria: dato } });
                //return this.$router.replace("cajas");
            }
        },
    },
    mounted() { },
};
</script>