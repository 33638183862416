<template>
    <NavbarApp>

       


        <div class="row">
            
            <div class="col-12 d-flex my-2">
                <b style="text-transform: uppercase;">RESUMEN DE MOVIMIENTOS POR CATEGORIA </b>
            </div>

            <div class="col-6 d-flex">
                <div class="card flex-fill">
                    <div class="card-body py-1">
                        <div class="d-flex align-items-start">
                            <div class="flex-grow-1">
                                <h4 class="mb-1">Bs. {{ parseFloat(mas_ingresos).toFixed(2) }}</h4>
                                <p class="mb-1">INGRESOS </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6 d-flex">
                <div class="card flex-fill">
                    <div class="card-body py-1">
                        <div class="d-flex align-items-start">
                            <div class="flex-grow-1">
                                <h4 class="mb-1">Bs. {{ parseFloat(mas_egresos).toFixed(2) }}</h4>
                                <p class="mb-1">EGRESOS</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="row">


            <div class="col-xl-12">

                <div class="card">

                    <div class="card-body">

                        <div class="row">
                            <table class="table table-striped table_padd">
                                <thead>
                                    <tr>
                                        <!-- <th>Nº</th> -->
                                        <th>CATEGORIA</th>
                                        <th class="text-start">+</th>
                                        <th class="text-end">-</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(dato) in sub_categorias" :key="dato">
                                        <!-- <td>{{ num + 1 }}</td> -->
                                        <td style="text-transform: uppercase;">
                                            <span v-on:click="movimientosSobreCuenta(dato)">{{ dato.nombre.substring(0, 50) }}</span>
                                        </td>
                                        <td class="text-start">
                                            <span
                                                style="color:green;">
                                                {{ parseFloat(dato.ingresos).toFixed(2) }}
                                            </span>
                                        </td>

                                        <td class="text-end">
                                            <span
                                                style="color:red;">
                                                {{ parseFloat(dato.egresos).toFixed(2) }}
                                            </span>
                                          
                                       </td>

                                    
                                    </tr>

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

            </div>
        </div>




    </NavbarApp>
</template>

<script>
// ../adminstracion/navbar/NavbarApp
import NavbarApp from "../../Layouts/carrito/NavbarCarrito.vue";
import axios from 'axios';
axios.defaults.baseURL = process.env.API_URL;
// const $ = require('jquery');

export default {
    name: "cajasUsuario",
    data: function () {
        return {
            sub_categorias: [],
            mas_ingresos:0,
            mas_egresos:0,
            id_usuario:'',
        };
    },
    components: {
        NavbarApp
    },
    created() {
     
        var dats_usuario    = JSON.parse(localStorage.getItem("usuarioAuth"));
        if(dats_usuario) {
            this.id_usuario = dats_usuario.id;
            this.iniciarResumen();
        }
        else {
            return this.$router.replace("/login");
        }
    },
    methods: {


        iniciarResumen: function () {
            this.getResumenCategoriasMovimiento();
        },
        getResumenCategoriasMovimiento: function () {
            axios.get('/api/getResumenCategoriasMovimiento/'+this.id_usuario).then(response => {
                response
                this.sub_categorias = response.data;

                this.mas_ingresos = 0;
                this.mas_egresos = 0;
                // this.saldo_cajas = 0;

                this.sub_categorias.forEach(categoria => {

                    this.mas_ingresos += parseFloat(categoria.ingresos);
                    this.mas_egresos += parseFloat(categoria.egresos);

                });

            }).catch(error => {
               
               if (error.response) {
                   if (error.response.statusText === 'Unauthorized') {
                       return this.$router.replace("/login");
                   }
               }
               else {
                   return this.$router.replace("/login");
               }
           });
        },
        movimientosSobreCuenta: function (dato) {
            this.$router.push({ path: "movimientosSobreCuenta", query: dato });
        }


    
    },
    mounted() {


    },
};
</script>

<style>
.table_padd th {
    padding: 0.2rem;
}

.table_padd td {
    padding: 0.2rem;
}
</style>