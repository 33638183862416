<template>
    <div v-if="showEliminarAdminitracion">
        <div class="modal" tabindex="-1">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <form @submit.prevent="updateAdministracion()">
                        <div class="modal-body" style="color:black;">

                          <center>
                            <p class="align-self-center text-center">
                                ¿Esta seguro de lemininar este registro?
                            </p>
                          </center>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary"
                                v-on:click="showEliminarAdminitracion = false">Cancelar</button>
                            <button type="submit" class="btn btn-danger">Si</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>



<script>

import axios from 'axios';
axios.defaults.baseURL = process.env.API_URL;

export default {
    name: "nuevaAdministracion",
    data: function () {
        return {
            showEliminarAdminitracion: false,
            dats_administracion: [],
        };
    },
    components: {

    },
    created() {
    },
    methods: {


        iniciarEliminarAdministracion: function (dats_administracion) {
            this.dats_administracion = dats_administracion;
            this.showEliminarAdminitracion = true;
        },
        updateAdministracion: function () {
            this.dats_administracion.estado = 0;
            axios.post('/api/updateAdministracion', this.dats_administracion).then(() => {
                this.showEliminarAdminitracion = false;
                this.$emit('getAdministracions');
            });
        },


    },
    mounted() {


    },
};
</script>