<template>
    <div class="main d-flex justify-content-center w-100">
        <main class="content d-flex p-0">
            <div class="container d-flex flex-column">
                <div class="row h-100">
                    <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                        <div class="d-table-cell align-middle">

                            <div class="text-center my-3">
                                <h1 class="h2" style="color: black;">Crear una Cuenta
                                </h1>
                            </div>

                            <div class="card">
                                <div class="card-body">
                                    <div class="m-sm-1">
                                        <div class="text-center">
                                            <!-- <img src="" alt="Chris Wood"
                                                class="img-fluid rounded-circle" width="132" height="132" /> -->
                                        </div>

                                        <form @submit.prevent="iniciarCrearUsuario">
                                            <div class="mb-3">
                                                <label class="form-label">Nombre</label>
                                                <input class="form-control form-control-lg" type="text"
                                                    placeholder="Nombre" v-model="nuevo_usuario.name" required/>
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">Correo Electrónico</label>
                                                <input class="form-control form-control-lg" type="email"
                                                    placeholder="Correo Electrónico" v-model="nuevo_usuario.email" required />
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">Contraseña</label>
                                                <input class="form-control form-control-lg" type="password"
                                                    placeholder="Contraseña" v-model="nuevo_usuario.password" required />
                                            </div>

                                            <div class="text-center mt-3">
                                                <button type="submit" class="btn btn-lg btn-primary">Registrarse</button>
                                            </div>

                                            <div class="text-center mt-3">
                                                ¿Tiene una cuenta?


                                                <router-link to="/login" exact>
                                                    Iniciar Sesión
                                                </router-link>

                                            </div>

                                            <div class="text-center mt-5" style="line-height: 1rem; font-size: 11px;">

                                                Gestiona tus finansas personales, Cajas de Ahorro, Proyectos, Presupuestos,
                                                Prestamos, Tareas, Contactos, reportes y mucho mas.

                                            </div>




                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<style>
.h2 {
    color: white;
}
</style>

<script>

import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.baseURL = process.env.API_URL;

export default {

    data: () => ({
        nuevo_usuario: {
            name:'',
            email: "",
            password: ''
        },
        nuevo_usuario_response:[],

    }),
    created() {

    },
    methods: {






        async iniciarCrearUsuario() {

            await this.crearUsuario();
            await this.$store.dispatch('login', this.nuevo_usuario);
            
            if (this.$store.state.usuario) {
                // var dato = {
                //     'id': 1,
                //     'nombre': 'Cajas',
                //     'id_usuario': 1
                // }
                var dato = this.$store.state.usuario.categoria_tipos[0];
                dato = JSON.stringify(dato);
                return this.$router.push({ name: 'administracion', params: { dats_categoria: dato } });
                //return this.$router.replace("cajas");
            }


        },


        crearUsuario: async function () {
            return  axios.post('/api/storeNuevoUsuario', this.nuevo_usuario).then(response => {
                this.nuevo_usuario_response = response.data;
            });
        }




    },
    mounted() { },
};
</script>