<template>
    <NavbarApp>
        <div class="row my-2">
            <div class="col-xl-12">

                <strong>REGISTRO DE TRANSFERENCIA</strong>
                <div class="card">
                    <div class="card-body ">
                        <form v-on:submit.prevent="storeTransferencia()">

                            <div class="row">

                                <div class="mb-2 col-12">
                                    <label for="id_caja">Cuenta Origen</label>
                                    <select class="form-select" v-model="new_movimiento.id_caja_origen" required="required"
                                    :style="[new_movimiento.id_caja_origen == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                    >
                                        <option value="">Seleccione una opción</option>
                                        <option v-for="caja in cajas" :value="caja.id" :key="caja">{{ caja.nombre }}
                                        | Bs. {{ parseFloat(caja.ingresos -
                                                caja.egresos).toFixed(2) }}
                                        </option>
                                    </select>
                                </div>
                                <div class="mb-2 col-12">
                                    <label for="id_caja">Cuenta Destino</label>
                                    <select class="form-select" v-model="new_movimiento.id_caja_destino" required="required"
                                    :style="[new_movimiento.id_caja_destino == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                    >
                                        <option value="">Seleccione una opción</option>
                                        <option v-for="caja in cajas" :value="caja.id" :key="caja">
                                            {{ caja.nombre }}  |   Bs. {{ parseFloat(caja.ingresos -
                                                caja.egresos).toFixed(2) }}
                                        </option>
                                    </select>
                                </div>

                                <div class="mb-2 col-12">
                                    <label for="id_caja">Fecha</label>
                                    <input class="form-control Float"
                                        :style="[new_movimiento.fecha == '' ? { 'background-color': 'white' } : { 'background-color': '#ebfbe9' }]"
                                        type="date" v-model="new_movimiento.fecha" required="required">
                                </div>

                                <div class="mb-2 col-12">
                                    <label for="id_caja">Descripción</label>
                                    <input class="form-control Float"
                                        :style="[new_movimiento.descripcion == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                        type="text" placeholder="Descripción" v-model="new_movimiento.descripcion"
                                        required="required">
                                </div>

                                <div class="mb-2 col-12">
                                    <label for="id_caja">Monto (Bs.)</label>
                                    <input class="form-control Float" type="number"
                                        :style="[new_movimiento.monto == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                        placeholder="Monto" step="any" v-model="new_movimiento.monto" required="required">

                                </div>

                                <div class="mb-2 col-12">
                                    <button type="submit" class="btn btn-primary">
                                        <i data-feather="plus"></i>
                                        Guardar
                                    </button>
                                </div>



                            </div>
                        </form>
                    </div>
                </div>


            </div>


        </div>

    </NavbarApp>
</template>

<script>

// import NavbarApp from "../adminstracion/navbar/NavbarApp";
import NavbarApp from "../Layouts/carrito/NavbarCarrito.vue";
import axios from 'axios';
axios.defaults.baseURL = process.env.API_URL;
import { Notyf } from 'notyf';
const notyf = new Notyf();


export default {
    name: "transferenciaEntraCajas",
    data: function () {
        return {

            new_movimiento: {},
            cajas: [],
            id_categoria_cajas_usuario:'',

        };
    },
    components: {
        NavbarApp,
    },
    created() {
        
        var dats_usuario    = JSON.parse(localStorage.getItem("usuarioAuth"));
        if(dats_usuario) {
            this.id_categoria_cajas_usuario = dats_usuario.categoria_tipos[0].id;
            this.iniciarTransferencia();
        }
        else {
            return this.$router.replace("/login");
        }

    },
    methods: {

        iniciarTransferencia: function () {
            this.getCajas();
            this.new_movimiento = {
                "id_caja_origen": "",
                "id_caja_destino": "",
                "fecha": this.fechaActualYMD(),
                "descripcion": "Transferencia",
                "monto": ""
            }
        },

        getCajas: function () {
            axios.get('/api/getAdministracion/'+this.id_categoria_cajas_usuario+'/ACTIVO').then(response => {
                this.cajas = response.data;
            }).catch(error => {
               
               if (error.response) {
                   if (error.response.statusText === 'Unauthorized') {
                       return this.$router.replace("/login");
                   }
               }
               else {
                   return this.$router.replace("/login");
               }
           });
        },
        
        storeTransferencia: function name() {
            axios.post('/api/storeTransferencia',this.new_movimiento).then(response =>{
                response
                this.notificacionSuccess('Transferencia registrada');
                this.iniciarTransferencia();
            });
        },

        notificacionSuccess: function (texto) {
            notyf.success({
                duration: 2500,
                position: {
                    x: 'right',
                    y: 'top',
                },
                message: texto
            });
        },
        fechaActualYMD() {
            let fechaHora = new Date();
            let anio = fechaHora.getFullYear();
            let mes = (fechaHora.getMonth() + 1).toString().padStart(2, '0');
            let dia = fechaHora.getDate().toString().padStart(2, '0');
            let fechaHoraFormateada = anio + '-' + mes + '-' + dia;
            return fechaHoraFormateada;
        },
    },
    mounted() {


    },
};
</script>

<style>
.table_padd th {
    padding: 0.2rem;
}

.table_padd td {
    padding: 0.2rem;
}
</style>