import { createApp } from 'vue';
import App from './App.vue';

import bootstrap from 'bootstrap';
// import "bootstrap/dist/css/bootstrap.min.css"
import "/public/plantillaAdminCarrito/assets/css/light.css";


import 'notyf/notyf.min.css';

import router from './router';
import store from './store';

store.dispatch("getUsuarioAutenticado");
//console.log(store.state.usuario);



const app   = createApp(App);
app.use(store);
app.use(router);
app.use(bootstrap);
app.mount('#app');



// createApp(App).use(router ,store,bootstrap).mount('#app')


