<template>
    <NavbarApp>
        <div class="row my-2">
            <div class="col-xl-12">

                <div class="card">
                    <div class="card-header">
                        <div class="card-actions float-end">
                            <div class="dropdown position-relative">
                                Opciones
                                <a href="#" data-bs-toggle="dropdown" data-bs-display="static">
                                    <i class="align-middle fa fa-list"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-end">
                                    <a class="dropdown-item" href="#" v-on:click="createPersona()">Registrar nuevo</a>
                                </div>
                            </div>
                        </div>
                        <h5 class="card-title mb-0" style="font-weight:600;">CONTACTOS</h5>
                    </div>
                    <div class="card-body">
                  
                            <table class="table table-striped table_padd">
                                <thead>
                                    <tr>
                                        <th>Nº</th>
                                        <th>NOMBRES</th>
                                        <th class="text-end">OPCIONES</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="dato, num in personas" :key="dato">
                                        <td>{{ num + 1 }}</td>

                                        <td style="text-transform: capitalize;">
                                            <p v-on:click="detallePersona(dato)" style="font-size: 12px;">
                                                {{ dato.name }} <br>
                                                <a href="#"> {{ dato.celular }}</a> <br>
                                                <small> <i>{{ dato.descripcion }}</i></small>
                                            </p>
                                        </td>
                                        <td class="text-end">
                                            <button class="btn btn-sm btn-primary" v-on:click="editarPersona(dato)"><i
                                                    class="fa fa-pencil-alt"></i></button>
                                            <!-- <button class="btn btn-sm btn-danger" v-on:click="eliminarPersona(dato)"><i
                                                    class="fa fa-trash"></i></button> -->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                

                    </div>
                </div>

            </div>
        </div>


        <NuevaPersona ref="nuevaPersona" @getPersonas="getPersonas"></NuevaPersona>
        <EditarPersona ref="editarPersona" @getPersonas="getPersonas"></EditarPersona>
        <DetallePersona ref="detallePersona"></DetallePersona>





    </NavbarApp>
</template>

<script>

// import NavbarApp from "../adminstracion/navbar/NavbarApp";
import NavbarApp from "../Layouts/carrito/NavbarCarrito.vue";
import NuevaPersona from "../M_Persona/NuevaPersona";
import EditarPersona from "../M_Persona/EditarPersona";
import DetallePersona from "../M_Persona/DetallePersona.vue";


import axios from 'axios';
axios.defaults.baseURL = process.env.API_URL;

export default {
    name: "proyectosUsuario",
    data: function () {
        return {
            personas: [],
            id_usuario:'',
        };
    },
    components: {
        NavbarApp, NuevaPersona, EditarPersona, DetallePersona
    },
    created() {


        var dats_usuario    = JSON.parse(localStorage.getItem("usuarioAuth"));
        if(dats_usuario) {
            this.id_usuario = dats_usuario.id;
            this.getPersonas();
        }
        else {
            return this.$router.replace("/login");
        }

    
    },
    methods: {

        getPersonas: function () {



            axios.get('/api/getUsuariosUsuario/' + this.id_usuario).then(response => {
                this.personas = response.data;
            }).catch(error => {
                if (error.response) {
                    if (error.response.statusText === 'Unauthorized') {
                        return this.$router.replace("/login");
                    }
                }
                else {
                    return this.$router.replace("/login");
                }
            });
        },
        createPersona: function () {
            this.$refs.nuevaPersona.iniciarNuevaPersona();
        },
        editarPersona: function (dato) {
            this.$refs.editarPersona.iniciarEditarPersona(dato);
        },
        detallePersona: function (dato) {
            this.$refs.detallePersona.iniciarDetallePersona(dato);
        }
        // eliminarPersona: function (dato) {

        // },





    },
    mounted() {


    },
};
</script>

<style>
.table_padd th {
    padding: 0.2rem;
}

.table_padd td {
    padding: 0.2rem;
}
</style>