<template>
    <div v-if="showNuevaPersona">
        <div class="modal" tabindex="-1">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-body" style="color:black;">

                        <p style="text-transform:uppercase;" class="align-self-center text-center">
                            <b>NUEVO CONTACTO</b>
                        </p>

                        <form @submit.prevent="storePersona()">

                            <div class="row">


                                <div class="mb-2 col-md-6">
                                    <label for="nombres">Nombres *</label>
                                    <input class="form-control String text-capitalize" type="text"  v-model="new_persona.name"
                                        placeholder="Nombres" required="required">
                                </div>

                                <div class="mb-2 col-md-6">
                                    <label for="contacto">Contacto 1</label>
                                    <input class="form-control Integer" type="number" v-model="new_persona.celular"
                                        placeholder="Contacto 1">
                                </div>

                                <div class="mb-2 col-md-6">
                                    <label for="contacto">Contacto 2</label>
                                    <input class="form-control Integer" type="number" v-model="new_persona.celular2"
                                        placeholder="Contacto 2">
                                </div>

                                <!-- <div class="mb-2 col-md-6">
                                    <label for="email">Email </label>
                                    <input class="form-control String" type="text" v-model="new_persona.email"
                                        placeholder="Email">
                                </div> -->

                                <div class="mb-2 col-md-6">
                                    <label for="contacto">Dirección *</label>
                                    <textarea class="form-control" cols="2" rows="2" v-model="new_persona.direccion"
                                        placeholder="Dirección"></textarea>
                                </div>

                                <div class="mb-2 col-md-6">
                                    <label for="contacto">Empresa *</label>
                                    <input class="form-control" type="text" v-model="new_persona.empresa"
                                        placeholder="Empresa">
                                </div>

                                <div class="mb-2 col-md-6">
                                    <label for="contacto">Descripción *</label>
                                    <textarea class="form-control" cols="2" rows="2" v-model="new_persona.descripcion"
                                        placeholder="Descripción"> </textarea>
                                </div>


                        
                            </div>

                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary"
                                    v-on:click="showNuevaPersona = false">Cerrar</button>
                                <button type="submit" class="btn btn-primary">Guardar</button>
                            </div>



                        </form>



                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>


import axios from 'axios';
axios.defaults.baseURL = process.env.API_URL;

export default {
    name: "proyectosUsuario",
    data: function () {
        return {
            showNuevaPersona: false,
            new_persona: {}
        };
    },
    components: {
    },
    created() {
    },
    methods: {

        iniciarNuevaPersona: function () {
            this.new_persona = {
                "name": "",
                "celular": "",
                "email": "default@default"+Math.floor(Math.random() * 10000) + 1+".com",
                "password": "default",
                "estado": "1",
                "celular2": "",
                "direccion": "",
                "empresa": "",
                "descripcion": "",
            }
            this.showNuevaPersona = true;
        },
        storePersona: function () {
            axios.post('/api/users', this.new_persona).then(() => {
                this.$emit('getPersonas');
                this.showNuevaPersona = false;
            });
        },
       

    },
    mounted() {


    },
};
</script>
