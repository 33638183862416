<template>
    <div v-if="showEditarTarea">
        <div class="modal" tabindex="-1">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <form v-on:submit.prevent="updateTarea()">
                        <div class="modal-body" style="color:black;">
                            <center>
                                <!-- <b style="text-transform:uppercase; font-weight: bold;">{{ titulo_modal }}</b> -->
                                <p style="text-transform: uppercase; font-size:12px">Editar Tarea</p>
                            </center>

                            <div class="row my-2">

                                <div class="mb-1 col-12">
                                    <label for="grado_confianza">Proyecto </label>
                                    <select class="form-select" v-model="id_proyecto"
                                        v-on:change="changeAdministracionProyecto()"
                                        :style="[id_proyecto == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                        style="text-transform: capitalize;">
                                        <option value="">Seleccione una opción</option>
                                        <option v-for="proyecto in proyectos" :value="proyecto.id" :key="proyecto">{{
                                            proyecto.nombre }}
                                        </option>
                                    </select>
                                </div>

                                <div class="mb-1 col-12">
                                    <label for="grado_confianza">Presupuesto </label>
                                    <select class="form-select" v-model="id_presupuesto"
                                        v-on:change="changeAdministracionPresupuesto()"
                                        :style="[id_presupuesto == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                        style="text-transform: capitalize;">
                                        <option value="">Seleccione una opción</option>
                                        <option v-for="presupuesto in presupuestos" :value="presupuesto.id"
                                            :key="presupuesto">{{ presupuesto.nombre }}
                                        </option>
                                    </select>
                                </div>



                                <div class="mb-1 col-12">
                                    <label for="prioridad">Prioridad</label>
                                    <select class="form-select" v-model="dats_tarea.prioridad"
                                        :style="[dats_tarea.prioridad == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                        required>
                                        <option value="">Seleccione una opción</option>
                                        <option value="URGENTE">URGENTE</option>
                                        <option value="IMPORTANTE">IMPORTANTE</option>
                                    </select>
                                </div>



                                <div class="mb-1 col-12">
                                    <label for="id_caja">Descripción</label>
                                    <textarea class="form-control Float"
                                        :style="[dats_tarea.descripcion == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                        placeholder="Descripción" v-model="dats_tarea.descripcion" rows="3" cols="3"
                                        required="required"> </textarea>
                                </div>


                                <div class="mb-1 col-12">
                                    <label for="id_caja">Estado</label>
                                    <select class="form-select" v-model="dats_tarea.estado"
                                        :style="[dats_tarea.estado == '' ? { 'background-color': 'white' } : { 'background-color': '#fff9c4' }]"
                                        required>
                                        <option value="">Seleccione una opción</option>
                                        <option value="1">PENDIENTE</option>
                                        <option value="2">COMPLETADO</option>
                                        <option value="0">ELIMINADO</option>
                                    </select>
                                </div>


                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary"
                                v-on:click="showEditarTarea = false">Cerrar</button>
                            <button type="submit" class="btn btn-primary">Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
axios.defaults.baseURL = process.env.API_URL;
import { Notyf } from 'notyf';
const notyf = new Notyf();

export default {
    name: "nuevoMovimiento",
    data: function () {
        return {
            showEditarTarea: false,
            dats_tarea: [],
            id_usuario: "",
            proyectos: [],
            presupuestos: [],

            id_proyecto: '',
            id_presupuesto: ''
        };
    },
    components: {

    },
    created() {
    },
    methods: {

        iniciarEditarTarea: function (dats_tarea,id_usuario) {
            this.id_usuario  = id_usuario;
            if (dats_tarea.administracion) {
                if (dats_tarea.administracion.id_categoria_tipo == 3) { // proyectos
                    this.id_presupuesto = '';
                    this.id_proyecto = dats_tarea.id_administracion;
                }
                else {
                    if (dats_tarea.administracion.id_categoria_tipo == 5) { // presupuesto
                        this.id_proyecto = '';
                        this.id_presupuesto = dats_tarea.id_administracion;
                    }
                }

            }
            else {
                this.id_presupuesto = '';
                this.id_proyecto = '';
            }



            this.dats_tarea = dats_tarea;
            this.getProyectos();
            this.getPresupuestos();
            this.showEditarTarea = true;
        },

        getProyectos: function () {
            axios.get('/api/getAdministracion/3/ACTIVO').then(response => {
                this.proyectos = response.data;
            });
        },

        getPresupuestos: function () {
            axios.get('/api/getAdministracion/5/ACTIVO').then(response => {
                this.presupuestos = response.data;
            });
        },

        changeAdministracionProyecto: function () {
            this.dats_tarea.id_administracion = this.id_proyecto;
            this.id_presupuesto = '';
        },
        changeAdministracionPresupuesto: function () {
            this.dats_tarea.id_administracion = this.id_presupuesto;
            this.id_proyecto = '';
        },

        updateTarea: function () {

            // if (this.dats_tarea.id_administracion == '') {
            //     return this.notificacion('Seleccionar Proyecto ó Presupuesto', 2000, 'error')
            // }
            this.dats_tarea.id_usuario = this.id_usuario;
            axios.post('/api/updateTarea', this.dats_tarea).then(() => {
                this.notificacion('Registrado Correctamente', 1000, 'success');
                this.$emit('getTareas');
                this.showEditarTarea = false;
            });
        },

        notificacion: function (texto, duracion, tipo) {
            if (tipo == 'success') {
                notyf.success({
                    duration: duracion,
                    position: {
                        x: 'right',
                        y: 'top',
                    },
                    message: texto
                });
            }
            else {
                if (tipo == 'error') {
                    notyf.error({
                        duration: duracion,
                        position: {
                            x: 'right',
                            y: 'top',
                        },
                        message: texto
                    });
                }
            }

        },

    },
    mounted() {


    },
};
</script>

<style>
.table_padd th {
    padding: 0.2rem;
}

.table_padd td {
    padding: 0.2rem;
}
</style>