<template>
    <div v-if="showEditarPersona">
        <div class="modal" tabindex="-1">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <form @submit.prevent="updatePersona()">
                        <div class="modal-body" style="color:black;">
                            <p style="text-transform:uppercase;" class="align-self-center text-center">
                                <b>EDITAR CONTACTO</b>
                            </p>
                            <div class="row">


                                <div class="mb-2 col-md-6">
                                    <label for="nombres">Nombres</label>
                                    <input class="form-control String" type="text" v-model="dats_persona.name"
                                        placeholder="Nombres" required="required">
                                </div>

                                <div class="mb-2 col-md-6">
                                    <label for="contacto">Contacto 1</label>
                                    <input class="form-control" type="number" v-model="dats_persona.celular"
                                        placeholder="Contacto 1">
                                </div>

                                <div class="mb-2 col-md-6">
                                    <label for="contacto">Contacto 2</label>
                                    <input class="form-control" type="number" v-model="dats_persona.celular2"
                                        placeholder="Contacto 2">
                                </div>

                                <div class="mb-2 col-md-6">
                                    <label for="email">Email</label>
                                    <input class="form-control String" type="text" v-model="dats_persona.email"
                                        placeholder="Email">
                                </div>

                                <div class="mb-2 col-md-6">
                                    <label for="contacto">Dirección</label>
                                    <textarea class="form-control"  cols="2" rows="2"
                                        v-model="dats_persona.direccion" placeholder="Dirección"></textarea>
                                </div>

                                <div class="mb-2 col-md-6">
                                    <label for="contacto">Empresa</label>
                                    <input class="form-control" v-model="dats_persona.empresa"
                                        placeholder="Empresa">
                                </div>

                                <div class="mb-2 col-md-6">
                                    <label for="contacto">Descripción</label>
                                    <textarea class="form-control" cols="2" rows="2"
                                        v-model="dats_persona.descripcion" placeholder="Descripción"> </textarea>
                                </div>



                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary"
                                v-on:click="showEditarPersona = false">Cerrar</button>
                            <button type="submit" class="btn btn-primary">Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


import axios from 'axios';
axios.defaults.baseURL = process.env.API_URL;

export default {
    name: "proyectosUsuario",
    data: function () {
        return {
            showEditarPersona: false,
            dats_persona: [],
        };
    },
    components: {

    },
    created() {

    },
    methods: {

        iniciarEditarPersona: function (dato) {
            this.dats_persona = dato;
            this.showEditarPersona = true;
        },
        updatePersona: function () {
            axios.put('/api/users/' + this.dats_persona.id, this.dats_persona).then(() => {
                this.showEditarPersona = false;
                this.$emit('getPersonas');
            });
        },


    },
    mounted() {


    },
};
</script>
