<template>
    <div v-if="showdetalleAdministracion">
        <div class="modal" tabindex="-1">
            <div class="modal-dialog modal-md">
                <div class="modal-content">

                    <div class="modal-body">

                        <div class="row">
                            <div class="mb-3 col-md-12" v-if="dats_administracion">
                                <table class="table table-striped table_padd">
                                    <tbody class="fw-semibold text-gray-800">

                                        <tr>
                                            <td>ID: </td>
                                            <td style="text-transform: uppercase;">
                                                {{ dats_administracion.id }}</td>
                                        </tr>
                                        <tr v-if="showCampos.id_categoria_tipo">
                                            <td>CATEGORIA: </td>
                                            <td style="text-transform: uppercase;">
                                                {{ dats_administracion.categoria.nombre }}
                                            </td>
                                        </tr>
                                        <tr v-if="showCampos.nombre">
                                            <td>NOMBRE: </td>
                                            <td style="text-transform: uppercase;">
                                                {{ dats_administracion.nombre }}</td>
                                        </tr>
                                        <tr v-if="showCampos.descripcion">
                                            <td>DESCRIPCIÓN: </td>
                                            <td style="text-transform: uppercase;">
                                                {{ dats_administracion.descripcion }}</td>
                                        </tr>


                                        <tr v-if="showCampos.id_usuario_origen">
                                            <td>DE: </td>
                                            <td style="text-transform: uppercase;">
                                                <span v-if="dats_administracion.usuario_origen">         {{ dats_administracion.usuario_origen.name }}</span>
                                       
                                            </td>
                                        </tr>
                                        <tr v-if="showCampos.id_usuario_destino">
                                            <td>A: </td>
                                            <td style="text-transform: uppercase;">
                                                <span v-if="dats_administracion.usuario_destino">{{ dats_administracion.usuario_destino.name }}</span>
                                                </td>
                                        </tr>
                                        <tr v-if="showCampos.fecha_inicio">
                                            <td>FECHA INICIO: </td>
                                            <td style="text-transform: uppercase;">{{ fechaActualYMD(dats_administracion.fecha_inicio) }}
                                            </td>
                                        </tr>
                                        <tr v-if="showCampos.fecha_final">
                                            <td>FECHA FINAL: </td>
                                            <td style="text-transform: uppercase;">{{ fechaActualYMD(dats_administracion.fecha_final) }}
                                            </td>
                                        </tr>
                                        <tr v-if="showCampos.interes">
                                            <td>INTERES: </td>
                                            <td style="text-transform: uppercase;">
                                                {{ dats_administracion.interes }} %</td>
                                        </tr>

         


                                        <tr>
                                            <td>INGRESOS: </td>
                                            <td style="text-transform: uppercase;">
                                             <small>Bs.</small>   {{ parseFloat(dats_administracion.ingresos).toFixed(2) }}</td>
                                        </tr>
                                        <tr>
                                            <td>EGRESOS: </td>
                                            <td style="text-transform: uppercase;">
                                                <small>Bs.</small>  {{ parseFloat(dats_administracion.egresos).toFixed(2) }} </td>
                                        </tr>
                                        <tr>
                                            <td>SALDO: </td>
                                            <td style="text-transform: uppercase;">
                                                <small>Bs.</small>  {{ parseFloat(dats_administracion.ingresos - dats_administracion.egresos).toFixed(2) }} </td>
                                        </tr>

                                        <tr>
                                            <td>ESTADO: </td>
                                            <td style="text-transform: uppercase;">
                                                {{ dats_administracion.estado_administracion }}
                                            </td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>



                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            v-on:click="showdetalleAdministracion = false">Cerrar</button>

                        <button type="button" class="btn btn-danger" v-on:click="eliminarAdministracion()">Eliminar</button>

                        <button type="button" class="btn btn-success" v-on:click="editarAdministracion()">Editar</button>

                    </div>


                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
axios.defaults.baseURL = process.env.API_URL;

export default {
    name: "nuevaAdministracion",
    data: function () {
        return {
            showdetalleAdministracion: false,
            dats_administracion:null,
            showCampos:[]
        };
    },
    components: {

    },
    created() {
    },
    methods: {

        iniciarDetalleAdministracion: function (dats_administracion,showCampos) {
            this.showCampos = showCampos;
            this.dats_administracion = dats_administracion;
            this.showdetalleAdministracion = true;
        },
        fechaActualYMD() {
            let fechaHora = new Date();
            let anio = fechaHora.getFullYear();
            let mes = (fechaHora.getMonth() + 1).toString().padStart(2, '0');
            let dia = fechaHora.getDate().toString().padStart(2, '0');
            let fechaHoraFormateada = anio + '-' + mes + '-' + dia;
            return fechaHoraFormateada;
        },

        
        editarAdministracion: function () {
            this.showdetalleAdministracion = false;
            this.$emit('editarAdministracion');
        },
        eliminarAdministracion: function () {
            this.showdetalleAdministracion = false;
            this.$emit('eliminarAdministracion');
        }

    },
    mounted() {


    },
};
</script>