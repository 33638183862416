
import { createStore } from 'vuex'

import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.baseURL = 'https://finans.sienegan.com';


// Create a new store instance.
export default createStore({


    state: {
        usuario: null,
        opcion_cajas: null,
        opcion_proyectos: null,
        opcion_presupuestos: null,
        opcion_prestamos: null,
        opcion_categorias: null
    },
    mutations: {
        SET_USER(state, usuario) {
            if (usuario) {
                state.usuario = usuario;
                state.opcion_cajas = usuario.categoria_tipos[0];
                state.opcion_proyectos = usuario.categoria_tipos[2];
                state.opcion_prestamos = usuario.categoria_tipos[3];
                state.opcion_presupuestos = usuario.categoria_tipos[4];
                state.opcion_categorias = usuario.categoria_tipos[5];
            }
            else {
                state.usuario = null;
                state.opcion_cajas = null;
                state.opcion_proyectos = null;
                state.opcion_presupuestos = null;
                state.opcion_prestamos = null;
                state.opcion_categorias = null;
            }

        }
    },
    actions: {
        async login({ dispatch }, credenciales) {

            await axios.get('sanctum/csrf-cookie');
            await axios.post('/login', credenciales);

            return await dispatch("getUsuarioAutenticado");
        },

        async logout({ dispatch }) {
            await axios.post('/logout');
            return await dispatch("getUsuarioAutenticado");
        },

        async getUsuarioAutenticado({ commit }) {
            return axios.get('/api/user').then(response => {
                commit("SET_USER", response.data);
                localStorage.setItem("usuarioAuth", JSON.stringify(response.data));
            }).catch(() => {
                localStorage.setItem("usuarioAuth", null);
                commit("SET_USER", null);
            });
        },




    },
    modules: {}
});



