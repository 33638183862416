<template>
    <NavbarApp>
        <div class="row mt-2">
            <div class="col-xl-12">

                <div class="card">
                    <div class="card-header">
                        <div class="card-actions float-end">
                            <div class="dropdown position-relative">
                                Opciones
                                <a href="#" data-bs-toggle="dropdown" data-bs-display="static">
                                    <i class="align-middle fa fa-list"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-end">
                                    <a class="dropdown-item" href="#" v-on:click="cambiarEstadoTareas(1)">Pendientes</a>
                                    <a class="dropdown-item" href="#" v-on:click="cambiarEstadoTareas(2)">Realizados</a>
                                    <a class="dropdown-item" href="#" v-on:click="cambiarEstadoTareas(0)">Eliminados</a>
                                </div>
                            </div>
                        </div>
                        <h5 class="card-title mb-0">
                            TAREAS
                            <b v-if="estado_tarea == 1">PENDIENTES</b>
                            <b v-if="estado_tarea == 2">REALIZADAS</b>
                            <b v-if="estado_tarea == 0">ELIMINADO</b>
                        </h5>
                    </div>
                    <div class="card-body">


                        <div class="row">

                            <div class="col-12 text-start d-flex">
                                <input type="text" class="form-control" style="width: 70%; margin-right: 5px;"
                                    placeholder="Buscar..." id="buscador" onkeyup="buscador()">

                                <button class="btn btn-md btn-primary" style="margin-right: 2px;" type="button"
                                    v-on:click="nuevaTarea()"> NUEVO</button>
                            </div>

                        </div>



                        <div class="row my-2">
                            <table class="table table-striped table_padd" id="tabla_a_buscar">

                                <thead>
                                    <tr>
                                        <th>Nº</th>
                                        <th>DESCRIPCIÓN</th>
                                        <th class="text-end"></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="(dato, num) in tareas" :key="dato">
                                        <td>{{ num + 1 }}</td>
                                        <td>
                                            <p style="line-height: 1;">
                                                <small v-if="dato.prioridad == 'IMPORTANTE'"
                                                    style="color:blue; font-weight:600; font-size:11px; cursor: pointer;"
                                                    v-on:click="editarTarea(dato)">{{ dato.prioridad }}</small>

                                                <small v-if="dato.prioridad == 'URGENTE'"
                                                    style="color:red; font-weight:600; font-size:11px; cursor: pointer;"
                                                    v-on:click="editarTarea(dato)">{{ dato.prioridad }}</small>
                                                <br>

                                                <span style="font-size:12px; text-transform:uppercase;" v-if="dato.administracion">
                                                    {{ dato.administracion.categoria.descripcion }}:
                                                    {{ dato.administracion.nombre }}
                                                    <br>
                                                </span>
                                           
                                                <span style="font-size:13px; text-transform:lowercase;">
                                                    "{{ dato.descripcion }}"
                                                </span>


                                            </p>
                                        </td>

                                        <td class="text-end">

                                            <!-- <button class="btn btn-sm btn-danger" v-on:click="completarTarea(dato)"><i
                                                    class="fa fa-clock"></i></button> -->
                                            <input class="form-check-input" v-on:click="completarTarea(dato)"
                                                style="width:30px; height:25px;" type="checkbox">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>


            </div>
        </div>


        <NuevaTarea ref="nuevaTarea" @getTareas="getTareas"></NuevaTarea>
        <EditarTarea ref="editarTarea" @getTareas="getTareas"></EditarTarea>

    </NavbarApp>
</template>

<script>

// import NavbarApp from "../adminstracion/navbar/NavbarApp";
import NavbarApp from "../Layouts/carrito/NavbarCarrito.vue";
import NuevaTarea from "../M_Tarea/NuevaTarea";
import EditarTarea from "../M_Tarea/EditarTarea";

import axios from 'axios';
axios.defaults.baseURL = process.env.API_URL;

export default {
    name: "tareas_user",
    data: function () {
        return {
            //1 = ACTIVO 2=COMPLETADO 0=ELIMINADO
            tareas: [],
            estado_tarea: '1',
            new_tarea: {},
            dats_tarea: [],
            id_usuario: '',
        };
    },
    components: {
        NavbarApp, NuevaTarea, EditarTarea
    },
    created() {
      
        var dats_usuario    = JSON.parse(localStorage.getItem("usuarioAuth"));
        if(dats_usuario) {
            this.id_usuario = dats_usuario.id;
            this.getTareas();
        }
        else {
            return this.$router.replace("/login");
        }


    },
    methods: {

        getTareas: function () {
            axios.get('/api/getTareas/' + this.estado_tarea+'/'+this.id_usuario).then(response => {
                this.tareas = response.data;
            });
        },
        cambiarEstadoTareas: function (estado_tarea) {
            this.estado_tarea = estado_tarea;
            this.getTareas();
        },
        nuevaTarea: function () {
            this.$refs.nuevaTarea.iniciarNuevaTarea(this.id_usuario);
        },
        editarTarea: function (dato) {

            this.$refs.editarTarea.iniciarEditarTarea(dato,this.id_usuario);
        },



        completarTarea: function (dato) {

            if (dato.estado == 1) {
                dato.estado = 2;
            }
            else {
                if (dato.estado == 2) {
                    dato.estado = 1;
                }
                else {
                    if (dato.estado == 0) {
                        dato.estado = 1;
                    }
                }
            }



            axios.post('/api/updateTarea', dato).then(() => {
                this.$refs.nuevaTarea.notificacion('Guardado',500,'success');
                this.getTareas();
            });
        }


    },
    mounted() {


    },
};
</script>

<style>
.table_padd th {
    padding: 0.2rem;
}

.table_padd td {
    padding: 0.2rem;
}
</style>